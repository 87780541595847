import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    Image,
    useColorMode,
    HStack,
    Badge,
    Alert,
    AlertIcon,
    useDisclosure,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    VStack
} from "@chakra-ui/react";

import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';

import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { BsUpload, BsSkipEnd } from 'react-icons/bs'
import { MdOutlineContentCopy, MdOutlineContactPage } from 'react-icons/md'

import SelectionCard from "./SelectionCard";
import CopyAndPasteModal from "./Modals/CopyAndPasteModal";
import UploadModal from "./Modals/UploadModal";
import UseOursModal from "./Modals/UseOursModal";

import { useNavigate } from 'react-router-dom';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




function ResumePage(props) {
    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen: copyPasteIsOpen, onOpen: openCopyPaste, onClose: closeCopyPaste } = useDisclosure()
    const { isOpen: uploadIsOpen, onOpen: openUpload, onClose: closeUpload } = useDisclosure()
    const { isOpen: useOursIsOpen, onOpen: openUseOurs, onClose: closeUseOurs } = useDisclosure()


    const { resumeText, setResumeText, uuid, jobDescriptionText, setJobDescriptionText, TTSAudio, practiceOption, yearsExperience, industry } = props
    const [pageLoaded, setPageLoaded] = useState(false)
    const [resumeAdequateInfo, setResumeAdequateInfo] = useState(true)

    const [resumeData, set_ResumeData] = useState([]);

    const [spinnerActive, setSpinnerActive] = useState(false)
    const navigate = useNavigate();


    // Hardcoded titles
    const titles = ["Software Engineering", "Pharmaceutical", "Accounting", "Investment Banking", "Mechanical Engineering", "Marketing", "Corporate Law", "Architecture", "Sales", "Cashier"];

    // Theming
    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, lg: false });



    // Warn user on refresh
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);

    // populate resumeData
    let called = false;
    useEffect(() => {
        if (!called) {
            called = true;

            TTSAudio.pause()

            setPageLoaded(true);
            setResumeText("")

        }
    }, []);





    const steps = [
        { title: 'Background' },
        { title: 'Resume' },
        practiceOption === 'Upcoming interview' ? { title: 'Job Description' } : { title: 'Interview' },
    ]

    const { activeStep } = useSteps({
        index: 1,
        count: steps.length,
    })


    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file.type !== "application/pdf") {
            console.error('Please upload a PDF file.');
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const pdfData = new Uint8Array(event.target.result);

            pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
                let textPromises = [];
                for (let i = 1; i <= pdf.numPages; i++) {
                    textPromises.push(pdf.getPage(i).then(page => {
                        return page.getTextContent();
                    }).then(content => {
                        return content.items.map(item => item.str).join(' ');
                    }));
                }

                Promise.all(textPromises).then(textPages => {
                    setResumeText(textPages.join('\n'))
                    openUpload();
                    // setResumeText(textPages.join('\n'));
                });
            });
        }

        reader.readAsArrayBuffer(file);

        // Reset the input value after processing the file
        event.target.value = null;
    }


    const startInterview = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/initialize`, {
                id: uuid,
                resume: resumeText,
                job_description: jobDescriptionText,
                yearsExperience: yearsExperience,
                industry: industry,
                practiceOption: practiceOption
            });

            // Navigate to interview page first and handle analytics stuff after
            navigate('/interview');


            const uniqueID = localStorage.getItem('uniqueID');

            // Check if the user is unique (via MongoDB)
            try {
                const isUniqueUserResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/checkUniqueUser`, {
                    uniqueID
                });

                if (isUniqueUserResponse.data.isUnique) {
                    // If the user is unique, trigger the Simple Analytics event
                    console.log('made it to 2')
                    window.sa_event('unique_interview_visit');
                    window.sa_event('Industry ' + industry);
                    window.sa_event('YOE' + yearsExperience);
                    window.sa_event('Combined ' + industry + " " + yearsExperience)
                }
            } catch (error) {
                console.error('Error checking unique user:', error);
            }



        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleUploadButtonPress = () => {

    }

    return (
        <div>
            <Flex
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems='center'
                backgroundColor={colorMode == 'dark' ? "gray.800" : ""}
                position='relative'
                transition="opacity .7s ease"
                opacity={pageLoaded ? 1 : 0}
            >
                <Flex position="fixed" top={2} align="center" zIndex='15' display={(resumeAdequateInfo) ? 'none' : ''}>
                    <Alert status='error' variant='solid'>
                        <AlertIcon />
                        Please provide a complete resume!
                    </Alert>
                </Flex>

                <Flex width='95vw' mt='5'>
                    <a href={process.env.REACT_APP_SERVER_URL}>
                        <Flex alignItems='start'>
                            <Image height="2.5rem" src='/ninja.png' objectFit='cover' me='2' />
                            <HStack alignItems='center' justifyContent='center' display='flex'>
                                <Heading size='lg' pb='1' bgColor={colorMode == 'dark' ? 'white' : 'black'} bgClip="text">CareerNinja</Heading>

                                <Badge fontSize='1rem' colorScheme='green'>
                                    .AI
                                </Badge>

                            </HStack>
                        </Flex>
                    </a >


                    <Spacer />
                    <Box>

                        {!isMobile ? (<Button leftIcon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />} size='md' variant='solid' onClick={toggleColorMode}>
                            {colorMode == 'light' ? "Dark Mode" : "Light Mode"}
                        </Button>) : (<IconButton onClick={toggleColorMode} size='md' icon={colorMode == 'light' ? <MoonIcon /> : <SunIcon />} />)}
                    </Box>
                </Flex>

                {!isMobile ?

                    (
                        <>

                            <Stepper index={activeStep} width={{ base: '100vw', md: '40rem', lg: '55rem' }} mt='12'>
                                {steps.map((step, index) => (
                                    <Step key={index}>
                                        <StepIndicator>
                                            <StepStatus
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                                active={<StepNumber />}
                                            />
                                        </StepIndicator>

                                        <Box flexShrink='0'>
                                            <StepTitle>{step.title}</StepTitle>
                                        </Box>

                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>
                            <Box height='3rem'>

                            </Box>
                        </>
                    )

                    :

                    (
                        <>
                            <Box height='1.5rem'>

                            </Box>

                            <Text fontSize='2xl'> Step 2 / 3</ Text >
                        </>
                    )
                }







                <Heading as='h2' size={isMobile ? 'xl' : 'xl'} mt={isMobile ? '3' :
                    '8'} width='85vw' textAlign='center' color={colorMode == 'dark' ? 'blue.50' : 'blue.900'}>
                    How do you want to upload your resume?
                </Heading>
                <Box height='1rem'>

                </Box>
                <Text fontSize='lg' w='85vw' textAlign='center'>Use your resume for a personalized experience</Text>

                {isMobile ? (

                    <VStack mt='7' mb='6' spacing='2rem' >
                        <input type="file" id="resume-upload" accept=".pdf" onChange={handleFileUpload} style={{ display: 'none' }} />

                        <SelectionCard ico={<BsUpload />} heading="Upload" subHeading="Use a PDF of your resume" clickFunction={() => document.getElementById('resume-upload').click()} />
                        <SelectionCard ico={<MdOutlineContentCopy />} heading="Copy and Paste" subHeading="Paste plain text" clickFunction={openCopyPaste} />
                        <SelectionCard ico={<BsSkipEnd />} heading="Skip" subHeading="Try it without a resume" clickFunction={practiceOption == "General practice" ? startInterview : () => { navigate('/jobdescription') }} spinnerActive={spinnerActive} />
                        <Button size='lg' leftIcon={<ChevronLeftIcon />} onClick={() => { navigate('/industry') }}>Back</Button>

                    </VStack>

                ) : (
                    <Box width='70rem'>
                        <Box height='3.5rem' />
                        <HStack mb='6' spacing='5rem'>
                            <input type="file" id="resume-upload" accept=".pdf" onChange={handleFileUpload} style={{ display: 'none' }} />
                            <SelectionCard ico={<BsUpload />} heading="Upload" subHeading="Use a PDF of your resume" clickFunction={() => document.getElementById('resume-upload').click()} />

                            <SelectionCard ico={<MdOutlineContentCopy />} heading="Copy and Paste" subHeading="Paste plain text" clickFunction={openCopyPaste} />
                            <SelectionCard ico={<BsSkipEnd />} heading="Skip" subHeading="Try it without a resume" clickFunction={practiceOption == "General practice" ? startInterview : () => { navigate('/jobdescription') }} spinnerActive={spinnerActive} />
                        </HStack>
                        <Button size='md' leftIcon={<ChevronLeftIcon />} onClick={() => { navigate('/industry') }}>Back</Button>
                    </Box>


                )
                }

                <CopyAndPasteModal copyPasteIsOpen={copyPasteIsOpen} openCopyPaste={openCopyPaste} closeCopyPaste={closeCopyPaste} setResumeText={setResumeText} inputType="resume" setResumeAdequateInfo={setResumeAdequateInfo} resumeText={resumeText} practiceOption={practiceOption} uuid={uuid} startInterview={startInterview} />
                <UploadModal uploadIsOpen={uploadIsOpen} openUpload={openUpload} closeUpload={closeUpload} resumeText={resumeText} setResumeText={setResumeText} setResumeAdequateInfo={setResumeAdequateInfo} practiceOption={practiceOption} uuid={uuid} jobDescriptionText={jobDescriptionText} startInterview={startInterview} />

                {resumeData.length > 0 && <UseOursModal inputType="resume" useOursIsOpen={useOursIsOpen} closeUseOurs={closeUseOurs} openUseOurs={openUseOurs} data={resumeData} setResumeText={setResumeText} setJobDescriptionText={setJobDescriptionText} practiceOption={practiceOption} />}

            </Flex >




        </div >
    );
}

export default ResumePage;