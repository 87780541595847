import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    Image,
    useColorMode,
    HStack,
    Badge,
    Alert,
    AlertIcon,
    useDisclosure,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    VStack,
    Select,
    useRadioGroup
} from "@chakra-ui/react";


import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';

import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon, ChevronLeftIcon } from '@chakra-ui/icons';
import { BsUpload } from 'react-icons/bs'
import { MdOutlineContentCopy, MdOutlineContactPage } from 'react-icons/md'
import UseOursModal from "./Modals/UseOursModal";
import RadioCard from "./RadioCard";

import SelectionCard from "./SelectionCard";
import CopyAndPasteModal from "./Modals/CopyAndPasteModal";

import { useNavigate } from 'react-router-dom';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;




function IndustryPage(props) {

    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen: copyPasteIsOpen, onOpen: openCopyPaste, onClose: closeCopyPaste } = useDisclosure()
    const { isOpen: uploadIsOpen, onOpen: openUpload, onClose: closeUpload } = useDisclosure()
    const { isOpen: useOursIsOpen, onOpen: openUseOurs, onClose: closeUseOurs } = useDisclosure()



    const { setYearsExperience, yearsExperience, industry, setIndustry, practiceOption, setPracticeOption } = props
    const [pageLoaded, setPageLoaded] = useState(false)

    const [adequateInfo, setAdequateInfo] = useState(true)
    const [otherIndustry, setOtherIndustry] = useState("")



    // Hardcoded titles
    const titles = ["Academia", "Aviation", "Agriculture", "Arts & Crafts", "Automotive", "Biotechnology", "Construction", "Consulting", "Computer Hardware", "Computer Software", "Consumer Electronics", "Data Science & Analytics", "Defense & Aerospace", "Design & Architecture", "E-Commerce", "Education", "Energy & Utilities", "Environmental Services", "Event Planning & Services", "Fashion & Apparel", "Finance & Banking", "Food & Beverage", "Gaming & Interactive Entertainment", "Government & Public Sector", "Health & Wellness", "Healthcare", "Hospitality & Tourism", "Human Resources", "Information Technology", "Insurance", "Legal Services", "Manufacturing", "Marketing & Advertising", "Media & Entertainment", "Mining & Metals", "Non-Profit", "Pharmaceuticals", "Public Relations", "Publishing", "Real Estate", "Research & Development", "Retail", "Robotics & Automation", "Security Services", "Software Development", "Sports & Recreation", "Telecommunications", "Transportation & Logistics", "Veterinary & Animal Care", "Other"];
    const experienceLevels = ["Less than 1 year", "1-2 years", "3-5 years", "6-10 years", "More than 10 years"]

    const navigate = useNavigate();


    // Warn user on refresh
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);


    // Function to be called on component initialization
    let called = false
    useEffect(() => {
        if (!called) {

            setPageLoaded(true)

            setIndustry("")
            setPracticeOption("")
            setYearsExperience("")


        }
    }, []);


    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, lg: false });

    const steps = [
        { title: 'Background' },
        { title: 'Resume' },
        practiceOption === 'Upcoming interview' ? { title: 'Job Description' } : { title: 'Interview' },
    ]


    const { activeStep } = useSteps({
        index: 0,
        count: steps.length,
    })

    const [jobDescriptionAdequateInfo, setJobDescriptionAdequateInfo] = useState(true)


    const handleUploadButtonPress = () => {

    }

    const handleContinue = async () => {

        if (((industry != "" && industry != "Other") || (industry == "Other" && otherIndustry != "")) && yearsExperience != "" && practiceOption != "") {
            if (industry == "Other") {
                setIndustry(otherIndustry)
            }

            const uniqueID = localStorage.getItem('uniqueID');

            navigate("/resume")


            // add the industry & yoe to MongoDB
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ industry: industry, yearsExperience: yearsExperience })
            };

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/add_industry_yoe_record`, requestOptions);
            const data = await response.json();

            // Check if the data was added successfully
            if (data.status !== 'Data added successfully') {
                console.error("Industry data not added");
            }


        } else {
            setAdequateInfo(false)
        }

    }

    const options = ['General practice', 'Upcoming interview']
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'framework',
        defaultValue: 'react',
        onChange: (value) => {
            setPracticeOption(value); // Update the selectedOption state with the selected value
        },
    })
    const group = getRootProps()

    return (
        <div>
            <Flex
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems='center'
                backgroundColor={colorMode == 'dark' ? "gray.800" : ""}
                position='relative'
                transition="opacity .7s ease"
                opacity={pageLoaded ? 1 : 0}


            >

                <Flex position="fixed" top={2} align="center" zIndex='15' display={(adequateInfo) ? 'none' : ''}>
                    <Alert status='error' >
                        <AlertIcon />
                        Please complete all fields!
                    </Alert>
                </Flex>

                <Flex width='95vw' mt='5'>
                    <a href={process.env.REACT_APP_SERVER_URL}>
                        <Flex alignItems='start'>
                            <Image height="2.5rem" src='/ninja.png' objectFit='cover' me='2' />
                            <HStack alignItems='center' justifyContent='center' display='flex'>
                                <Heading size='lg' pb='1' bgColor={colorMode == 'dark' ? 'white' : 'black'} bgClip="text">CareerNinja</Heading>

                                <Badge fontSize='1rem' colorScheme='green'>
                                    .AI
                                </Badge>

                            </HStack>
                        </Flex>
                    </a >


                    <Spacer />
                    <Box>

                        {!isMobile ? (<Button leftIcon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />} size='md' variant='solid' onClick={toggleColorMode}>
                            {colorMode == 'light' ? "Dark Mode" : "Light Mode"}
                        </Button>) : (<IconButton onClick={toggleColorMode} size='md' icon={colorMode == 'light' ? <MoonIcon /> : <SunIcon />} />)}
                    </Box>
                </Flex>

                {!isMobile ?

                    (
                        <>

                            <Stepper index={activeStep} width={{ base: '100vw', md: '40rem', lg: '55rem' }} mt='8'>
                                {steps.map((step, index) => (
                                    <Step key={index}>
                                        <StepIndicator>
                                            <StepStatus
                                                complete={<StepIcon />}
                                                incomplete={<StepNumber />}
                                                active={<StepNumber />}
                                            />
                                        </StepIndicator>

                                        <Box flexShrink='0'>
                                            <StepTitle>{step.title}</StepTitle>
                                        </Box>

                                        <StepSeparator />
                                    </Step>
                                ))}
                            </Stepper>
                            <Box height='2rem'>

                            </Box>
                        </>
                    )

                    :

                    (
                        <>
                            <Box height='1rem'>

                            </Box>

                            <Text fontSize='2xl'> Step 1 / 3</ Text >
                        </>
                    )
                }







                <Heading as='h2' size={isMobile ? 'xl' : 'xl'} mt={isMobile ? '4' :
                    '4'} width={{ base: '85vw', md: '100vw' }} textAlign='center' color={colorMode == 'dark' ? 'blue.50' : 'gray.700'} fontWeight='bold'>
                    Let us personalize your interview
                </Heading>
                <Box height={isMobile ? '2rem' : '3rem'}>

                </Box>

                <Box width={{ base: '95vw', md: "30rem" }} height='' mb='5' rounded='xl' justifyContent='center' display='flex' bgColor={colorMode == 'dark' ? 'gray.900' : 'gray.50'} borderWidth='1px' shadow={colorMode == 'dark' ? 'dark-lg' : ''}>
                    <VStack width='80%' mt='12'>
                        <Heading size='sm' textAlign='center' fontWeight='semibold' mb='3'>What does your background look like?</Heading>


                        <Select size='md' mb='3' placeholder="Select industry" value={industry} onChange={(e) => { setIndustry(e.target.value) }} >
                            {titles.map((value) => {
                                return (
                                    <option key={value}>
                                        {value}
                                    </option>
                                )
                            })}
                        </Select>
                        <Input placeholder='Please specify industry' size='md' mb='3' display={industry == "Other" ? '' : 'none'} value={otherIndustry} onChange={(e) => { setOtherIndustry(e.target.value) }} />
                        <Select size='md' mb='6' placeholder="Select experience" value={yearsExperience} onChange={(e) => { setYearsExperience(e.target.value) }}>
                            {experienceLevels.map((value) => {
                                return (
                                    <option key={value}>
                                        {value}
                                    </option>
                                )
                            })}

                        </Select>

                        <Heading size='sm' textAlign='center' fontWeight='semibold' mb='2'>I'm prepping for (an)</Heading>

                        <HStack {...group}>
                            {options.map((value) => {
                                const radio = getRadioProps({ value })
                                return (
                                    <RadioCard key={value} {...radio}>
                                        {value}
                                    </RadioCard>
                                )
                            })}
                        </HStack>



                        <Flex width='100%' my='6'>
                            <Button onClick={() => navigate('/')}>Back</Button>
                            <Spacer />
                            <Button colorScheme='gray' textColor='green.400' onClick={handleContinue}>Continue</Button>
                        </Flex>


                    </VStack>


                </Box>

            </Flex>






        </div >
    );
}

export default IndustryPage;