import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container, VStack, useColorMode } from '@chakra-ui/react';

import { ArrowForwardIcon } from '@chakra-ui/icons';
import { BsRecordCircle, BsStopCircleFill, BsLightbulb } from 'react-icons/bs';


import {
    Box,
    Heading,
    Progress,
    Stack,
    StackDivider,
    Text,
    Image,
    Button,
    ButtonGroup,
    Textarea,
    Wrap,
    WrapItem,
    Flex,
    Spacer,
    Tooltip
} from "@chakra-ui/react";




function Controls(props) {
    const { isLoading, inputMode, handleSubmit, isRecording, stopRecording, startRecording, handleSkip, handleHint, endTime, responseText, setResponseText, minutesLeft, transcript, responded, disableControls } = props
    const endTimeString = endTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    const { colorMode, toggleColorMode } = useColorMode();
    const contentRef = useRef(null);

    useEffect(() => {
        // Scroll to the bottom of chat dialog whenever it overflows.
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [transcript, responseText]);


    const gradientColors = colorMode === 'dark'
        ? "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))"
        : "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))";


    const getRemainingTimeText = () => {
        if (minutesLeft > 1) {
            return `${minutesLeft} minutes left`;
        } else if (minutesLeft === 1) {
            return '1 minute left';
        } else {
            return 'Time is up!';
        }
    };

    return (
        <VStack>

            <Box transition="opacity .5s ease" opacity={responded || inputMode ? 1 : 0} width={{ base: '90vw', md: '30rem' }} height='8rem' boxShadow='md' rounded='lg' css={{
                // backdropFilter: "blur(8px)",
                backgroundImage: gradientColors, // Adjust the transparency here
            }}>

                {isRecording ?
                    // If a user is recording a response, then show the transcript. Otherwise, show the normal text
                    (<Textarea ref={contentRef} placeholder='Listening...' height='100%' value={transcript} textColor='white' onChange={(e) => setResponseText(e.target.value)} css={{
                        '&::-webkit-scrollbar': {
                            width: '0.4em',  // Adjust the width as per your preference
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: 'transparent',
                        },
                        "::placeholder": {
                            color: "lightgray", // Change this to your desired placeholder text color
                        },
                    }} />

                    ) : (<Textarea ref={contentRef} placeholder='Your Response' height='100%' value={responseText} textColor='white' onChange={(e) => setResponseText(e.target.value)} css={{
                        '&::-webkit-scrollbar': {
                            width: '0.4em',  // Adjust the width as per your preference
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'transparent',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            background: 'transparent',
                        },
                        "::placeholder": {
                            color: "lightgray", // Change this to your desired placeholder text color
                        },
                    }} />)}
            </Box>
            <Box textAlign="center" bgGradient={colorMode == 'dark' ? 'linear(to-r, gray.600, gray.700)' : 'linear(to-r, gray.50, gray.50)'} rounded='lg' boxShadow='dark-lg' display="flex" alignItems="center" justifyContent='center' px='4' maxWidth='90vw'>

                <Box pb='2' pt='3' px='1' maxWidth='100%'>
                    <Text transition="opacity .5s ease" opacity={endTime && minutesLeft != -999 ? 1 : 0} fontSize='md' mb={3}>Interview ends at {endTimeString} ({getRemainingTimeText()})</Text>

                    <Wrap justify="center" spacing={2} pb={2} maxWidth="100%">
                        <WrapItem>
                            <Tooltip label={isLoading || disableControls ? "Please wait for text to finish typing" : ""}>
                                <Button
                                    colorScheme="orange"
                                    isDisabled={isRecording || isLoading || disableControls}
                                    size="sm"
                                    onClick={handleSkip}
                                >
                                    Skip Question
                                </Button>
                            </Tooltip>
                        </WrapItem>
                        <WrapItem>
                            <Tooltip label={isLoading || disableControls ? "Please wait for text to finish typing" : ""}>
                                <Button
                                    colorScheme="orange"
                                    isDisabled={isRecording || isLoading || disableControls}
                                    onClick={handleHint}
                                    rightIcon={<BsLightbulb />}
                                    size="sm"
                                >
                                    Hint
                                </Button>
                            </Tooltip>
                        </WrapItem>
                        <WrapItem>
                            <Tooltip label={isLoading || disableControls ? "Please wait for text to finish typing" : ""}>
                                <Button
                                    size="sm"
                                    isDisabled={isLoading || disableControls}
                                    loadingText="Loading"
                                    onClick={inputMode ? handleSubmit : isRecording ? stopRecording : startRecording}
                                    colorScheme={inputMode ? 'whatsapp' : isRecording ? 'red' : 'whatsapp'}
                                    rightIcon={inputMode ? <ArrowForwardIcon /> : isRecording ? <BsRecordCircle size={18} /> : <BsStopCircleFill size={18} />}
                                >
                                    {inputMode ? 'Submit Response' : isRecording ? 'End Response' : 'Begin Response'}
                                </Button>
                            </Tooltip>
                        </WrapItem>
                        <WrapItem display={!inputMode ? (responded ? '' : 'none') : 'none'}>
                            <Tooltip label={isLoading || disableControls ? "Please wait for text to finish typing" : ""}>
                                <Button
                                    colorScheme="whatsapp"
                                    isDisabled={isRecording || isLoading || disableControls}
                                    size="sm"
                                    onClick={handleSubmit}
                                    rightIcon={<ArrowForwardIcon />}
                                >
                                    Submit
                                </Button>
                            </Tooltip>
                        </WrapItem>
                    </Wrap>
                </Box>
            </Box>
        </VStack >
    )


}

export default Controls