import React, { useState, useRef, useEffect } from "react";
import {
    Textarea,
    Spacer,
    Button,
    useBreakpointValue,
    useColorMode,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Flex,
    Alert,
    AlertIcon,
    Text
} from "@chakra-ui/react";



import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsUpload } from 'react-icons/bs'
import { MdOutlineContentCopy, MdOutlineContactPage } from 'react-icons/md'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';



import SelectionCard from "../SelectionCard";



function UploadModal(props) {
    const { uploadIsOpen, openUpload, closeUpload, resumeText, setResumeText, setResumeAdequateInfo, practiceOption, uuid, jobDescriptionText, startInterview } = props
    const navigate = useNavigate();
    const { colorMode, toggleColorMode } = useColorMode();


    let called = false
    useEffect(() => {
        // Function to be called on component initialization
        if (!called) {

        }
    }, []);


    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, lg: false });




    const handleResumeSubmit = () => {
        if (resumeText.length < 10) {
            setResumeAdequateInfo(false)
        } else if (practiceOption == "Upcoming interview") {
            navigate("/jobdescription")
        } else {
            startInterview()
        }
    }

    return (
        <div>

            <Modal onClose={closeUpload} isOpen={uploadIsOpen} isCentered >
                <ModalOverlay zIndex={10} />

                <ModalContent minWidth={{ base: '90vw', md: '45rem' }} maxWidth={{ base: '90vw', md: '45rem' }}>
                    <ModalHeader pb='2'>Uploaded Text</ModalHeader>
                    <Text ms='6' mb='5' maxW='80vw'>Note: Our systems don't require formatting </Text>
                    <ModalCloseButton />
                    <ModalBody>
                        <Textarea placeholder='Paste your resume here' height='60vh' value={resumeText} onChange={(e) => setResumeText(e.target.value)} />
                    </ModalBody>
                    <ModalFooter>

                        <Button me='2' onClick={closeUpload} >Cancel</Button>
                        <Button me='2' onClick={() => document.getElementById('resume-upload').click()} >Reupload</Button>

                        <Button onClick={handleResumeSubmit} colorScheme="blue">Continue</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </div >
    );
}

export default UploadModal;