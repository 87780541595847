import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    Image,
    useColorMode,
    HStack,
    Badge,
    Alert,
    AlertIcon,
    useDisclosure,
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
    ListItem,
    ListIcon,
    UnorderedList,
    List,
    Card, CardHeader, CardBody, CardFooter, VStack
} from "@chakra-ui/react";



import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsPerson } from 'react-icons/bs'




function DescriptionCard(props) {
    const { currObject, inputType } = props

    const { colorMode, toggleColorMode } = useColorMode();


    let called = false
    useEffect(() => {
        // Function to be called on component initialization
        if (!called) {

        }
    }, []);


    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [isHovered, setIsHovered] = useState(false);

    const items = ["Item 1", "Item 2"];
    const bulletPointList = currObject.description.map((item, index) => (
        <ListItem key={index}>
            {item}
        </ListItem>
    ));

    return (

        <Card
            shadow={colorMode == "dark" ? 'dark-lg' : 'lg'}
            width={{ base: "80vw", md: '25rem' }}

        >
            <CardBody mb='6' mt='2'>

                <IconButton colorScheme="orange" icon={<BsPerson />} me="4" size="md" mb='4' />
                <VStack alignItems="start" spacing="2">
                    <Heading
                        as="h4"
                        size="md"
                        mb='2'
                        color={colorMode == 'dark' ? isHovered ? "green.200" : "white" : isHovered ? "green.500" : "black"
                        } // Change color on hover
                    >
                        {currObject.title}
                    </Heading>
                    {inputType == "resume" ? (<></>) : (

                        <Text fontSize='lg  '>Responsibilities</Text>

                    )}
                    <UnorderedList>
                        {bulletPointList}
                    </UnorderedList>
                </VStack>
            </CardBody>
        </Card >

    );
}

export default DescriptionCard;