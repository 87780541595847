import { Button } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container, useDisclosure } from '@chakra-ui/react';
import numberToWords from 'number-to-words';


import {
    Box,
    Heading,
    Progress,
    Stack,
    StackDivider,
    Text,
    useColorMode,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Tooltip
} from "@chakra-ui/react";

import { HamburgerIcon } from '@chakra-ui/icons'

function ProgressCardMobile(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();
    const { completedQuestions, skippedQuestions, handleEnd, minutesPassed, secondsPassed, duration, isLoading, isOpen, onOpen, onClose, disableControls } = props


    const gradientColors = colorMode === 'dark'
        ? ['gray.700', 'gray.800']
        : ['gray.200', 'gray.300'];

    function capitalizeFirstCharacter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const endText = "Thank you for your responses. Please give me a moment while I generate your feedback"


    return (
        <>
            <Button

                mb={2}
                variant='solid'
                size="sm"
                onClick={onOpen}
            >

                <HamburgerIcon me={2} />
                Your Progress
            </Button>

            <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent maxWidth={{ base: '85vw', md: '25rem' }}>
                    <ModalHeader>Your Progress</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Box>
                            <Stack divider={<StackDivider />} spacing='4'>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Questions Answered
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {capitalizeFirstCharacter(numberToWords.toWords(completedQuestions))} answered
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Questions Skipped
                                    </Heading>
                                    <Text pt='2' fontSize='sm'>
                                        {capitalizeFirstCharacter(numberToWords.toWords(skippedQuestions))} skipped
                                    </Text>
                                </Box>
                                <Box>
                                    <Heading size='xs' textTransform='uppercase'>
                                        Progress
                                    </Heading>
                                    <Text pt='3' pb='3' fontSize='sm' >
                                        {(minutesPassed != -999 && secondsPassed != -999 && duration != - 999) ? `Time: ${String(minutesPassed)}:${String(secondsPassed).padStart(2, '0')} / ${duration}:00` : ""}
                                    </Text>
                                    <Progress value={((minutesPassed * 60 + secondsPassed) / (duration * 60)) * 100} rounded='md' />
                                </Box>
                                <Box mb='0' pb='0'>
                                    <Tooltip label={isLoading || disableControls ? "Please wait for text to finish typing" : ""}>
                                        <Button mb='0' colorScheme='red' size='sm' isDisabled={isLoading || disableControls} onClick={() => handleEnd(endText, true)}>End Interview</Button>
                                    </Tooltip >
                                </Box>
                            </Stack>
                        </Box>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>




        </>
    );

}

export default ProgressCardMobile