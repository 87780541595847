import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container, useBreakpointValue, IconButton, Icon } from '@chakra-ui/react';

import { ArrowForwardIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { BsArrowReturnLeft, BsFillSkipEndFill } from 'react-icons/bs';

import CursorSVG from "./CursorSVG";

import {
    Box,
    Stack,
    Image,
    Button,
    Skeleton,
    Center,
    Heading,
    Spinner,
    ButtonGroup,
    useColorMode,
    Alert,
    AlertIcon,
    Flex,
    VStack
} from "@chakra-ui/react";



function DialogBox(props) {
    const { hasBegun, intervalID, hintActive, handleStart, headingText, isLoading, activateSpinner, setHintActive, setHeadingText, currentQuestion, TTSAudio, setDisableControls, fastForwardAvailable, handleSkipDialogue } = props
    const isMobile = useBreakpointValue({ base: true, md: false });
    const contentRef = useRef(null);
    const { colorMode, toggleColorMode } = useColorMode();

    useEffect(() => {
        // Scroll to the bottom of chat dialog whenever it overflows.
        if (contentRef.current) {
            contentRef.current.scrollTop = contentRef.current.scrollHeight;
        }
    }, [headingText]);


    const handleHintSwitching = () => {
        clearInterval(intervalID)
        TTSAudio.pause()
        setHintActive(false);
        setDisableControls(false)
        setHeadingText(currentQuestion);
    }

    return (
        <>
            <Box width={!isMobile ? '38rem' : '90vw'} height="7rem" textAlign="start" bgGradient={colorMode == 'dark' ? "linear(to-r, gray.600, gray.700)" : "linear(to-r, gray.50, gray.50)"} rounded='lg' boxShadow='dark-lg' display="flex" alignItems="center" justifyContent='start' px='4' >
                <Image height={{ base: '2.5rem', md: '3.5rem' }} src='/ninja.png' objectFit='cover' me='3' />
                {/* https://www.flaticon.com/free-icon/ninja_1071164 */}
                <Box overflow="auto" ref={contentRef} height="5rem" width='100%' justifyContent='center' alignItems='center' display={isLoading ? 'flex' : ''} css={{
                    '&::-webkit-scrollbar': {
                        width: '0.4em',  // Adjust the width as per your preference
                    },
                    '&::-webkit-scrollbar-track': {
                        background: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: 'transparent',
                    },
                }}>
                    <Heading as='h5' size='sm' display={isLoading ? 'none' : ''}>
                        <span style={{ marginRight: '10px' }}>{headingText}</span>
                        {activateSpinner && <Spinner size='xs' color={colorMode == 'dark' ? 'white' : 'black'} />}
                    </Heading>

                    <Stack width="100%" height="100%" justifyContent='center' alignItems='center' display={isLoading ? 'flex' : 'none'}>
                        <Skeleton width='90%' height='15px' />
                        <Skeleton width='90%' height='15px' />
                        <Skeleton width='90%' height='15px' />
                    </Stack>
                </Box>

                <VStack>
                    <IconButton colorScheme='white' onClick={handleSkipDialogue} variant='ghost' display={fastForwardAvailable ? 'flex' : 'none'} icon={<BsFillSkipEndFill />} />
                    <IconButton colorScheme='white' onClick={handleHintSwitching} variant='ghost' display={hintActive ? 'flex' : 'none'} icon={<BsArrowReturnLeft />} />
                </VStack>

            </Box >

            <ButtonGroup variant='solid' spacing='3' pb='2' transition="opacity 0.3s ease" opacity={hasBegun ? 0 : 1}>

                <Button colorScheme='blue' mt='2' rightIcon={<ArrowForwardIcon />} size='sm'
                    isDisabled={hasBegun}
                    onClick={() => handleStart(5)}
                >5 mins</Button>
                <Button colorScheme='blue' mt='2' rightIcon={<ArrowForwardIcon />} size='sm'
                    isDisabled={hasBegun}
                    onClick={() => handleStart(15)}
                >15 mins</Button>
                <Button colorScheme='blue' mt='2' rightIcon={<ArrowForwardIcon />} size='sm'
                    isDisabled={hasBegun}
                    onClick={() => handleStart(30)}
                >30 mins</Button>
            </ButtonGroup>





        </>
    )


}

export default DialogBox