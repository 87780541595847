import { Button } from "@chakra-ui/react";
import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container } from '@chakra-ui/react';
import numberToWords from 'number-to-words';


import {
    Box,
    Heading,
    Progress,
    Stack,
    StackDivider,
    Text,
    useColorMode,
    Tooltip
} from "@chakra-ui/react";

import { HamburgerIcon } from '@chakra-ui/icons'

function ProgressCard(props) {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const { completedQuestions, skippedQuestions, handleEnd, minutesPassed, secondsPassed, duration, isLoading, disableControls } = props
    const { colorMode, toggleColorMode } = useColorMode();
    const gradientColors = colorMode === 'dark'
        ? ['gray.700', 'gray.800']
        : ['gray.200', 'gray.300'];

    const endText = "Thank you for your responses. Please give me a moment while I generate your feedback"


    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    function capitalizeFirstCharacter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }



    return (
        <>
            <Button
                onClick={toggleMenu}
                boxShadow='lg'
                mb={2}
                bgGradient={colorMode == 'dark' ? "linear(to-r, gray.600, gray.700)" : ""}
                backgroundColor="gray.50"
                _hover={{
                    bgGradient: `linear(to-r, ${gradientColors.join(', ')})`,
                }}
                size="sm">

                <HamburgerIcon me={2} />
                Your Progress
            </Button >
            <Box
                transition="opacity 0.3s ease"
                opacity={isMenuOpen ? 1 : 0}
                bgGradient={colorMode == 'dark' ? "linear(to-r, gray.600, gray.700)" : "linear(to-r, gray.50, gray.100)"}
                p={5}
                borderRadius="md"
                boxShadow="md"
                maxWidth="15rem"
            >
                <Box>
                    <Stack divider={<StackDivider />} spacing='4'>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                                Questions Answered
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {capitalizeFirstCharacter(numberToWords.toWords(completedQuestions))} answered
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                                Questions Skipped
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                                {capitalizeFirstCharacter(numberToWords.toWords(skippedQuestions))} skipped
                            </Text>
                        </Box>
                        <Box>
                            <Heading size='xs' textTransform='uppercase'>
                                Progress
                            </Heading>
                            <Text pt='3' pb='3' fontSize='sm' >
                                {(minutesPassed != -999 && secondsPassed != -999 && duration != - 999) ? `Time: ${String(minutesPassed)}:${String(secondsPassed).padStart(2, '0')} / ${duration}:00` : ""}
                            </Text>
                            <Progress value={((minutesPassed * 60 + secondsPassed) / (duration * 60)) * 100} rounded='md' />
                        </Box>
                        <Box mb='0' pb='0'>
                            <Tooltip label={isLoading || disableControls ? "Please wait for text to finish typing" : ""}>
                                <Button mb='0' colorScheme='red' size='sm' isDisabled={isLoading || disableControls} onClick={() => handleEnd(endText, true)}>End Interview</Button>
                            </Tooltip >
                        </Box>
                    </Stack>
                </Box>
            </Box>
        </>
    );

}

export default ProgressCard