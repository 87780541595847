import { useState, useRef, useEffect } from "react";
import {
    Box,
    Container, Flex,
    Heading,
    AbsoluteCenter,
    Textarea,
    Text,
    Button,
    useBreakpointValue,
    useColorMode,
    ButtonGroup,
    IconButton,
    useDisclosure,
    Center,
    Alert,
    AlertIcon,
    Badge,
    Spacer,
    HStack,
    Image,
    Switch
} from "@chakra-ui/react";

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from "@chakra-ui/react";

import ProgressCardMobile from "./ProgressCardMobile";
import ProgressCard from "./ProgressCard";
import DialogBox from "./DialogBox";
import Controls from "./Controls";

import Webcam from 'react-webcam';
import { useMediaRecorder } from 'react-media-recorder';
import { useNavigate } from 'react-router-dom';

import { SunIcon, HamburgerIcon, MoonIcon } from '@chakra-ui/icons';
import { BsFillPencilFill, BsFillWebcamFill, BsFillVolumeUpFill, BsFillVolumeMuteFill } from 'react-icons/bs';

import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


function InterviewPageV2(props) {
    const { uuid, name, setOverallFeedback, setQuestionFeedback, TTSAudio, setTTSAudio } = props
    const [inputMode, setInputMode] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [hasBegun, setHasBegun] = useState(false);
    const [headingText, setHeadingText] = useState("");
    const [responseText, setResponseText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hintActive, setHintActive] = useState(false);
    const [intervalID, setIntervalID] = useState('');
    const [currentQuestion, setCurrentQuestion] = useState("")
    const [completedQuestions, setCompletedQuestions] = useState(0);
    const [skippedQuestions, setSkippedQuestions] = useState(0);
    const [activateSpinner, setActivateSpinner] = useState(false);
    const { colorMode, toggleColorMode } = useColorMode();
    const [isMuted, setIsMuted] = useState(false)
    const [hasEnded, setHasEnded] = useState(false)
    const [responded, setResponded] = useState(false)
    const [pageLoaded, setPageLoaded] = useState(false)

    const [fastForwardAvailable, setFastForwardAvailable] = useState(false)
    const [currentText, setCurrentText] = useState("")

    // Controls
    const [hideControls, setHideControls] = useState(false)
    const [disableControls, setDisableControls] = useState(false)



    // Timer stuff
    const [minutesLeft, setMinutesLeft] = useState(-999)
    const [minutesPassed, setMinutesPassed] = useState(-999)
    const [secondsPassed, setSecondsPassed] = useState(-999)
    const [endTime, setEndTime] = useState(false)
    const [startTime, setStartTime] = useState(false)
    const [duration, setDuration] = useState(-999)


    const navigate = useNavigate();

    // for api limit modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    // For server error modal
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    // For progress modal on mobile
    const { isOpen, onOpen, onClose } = useDisclosure()


    const onModalClose = () => setIsModalOpen(false);

    const isMobile = useBreakpointValue({ base: true, md: false });
    const isMedium = useBreakpointValue({ base: true, lg: false });


    const returnHome = () => {
        navigate('/')
    }

    ///////////////////////////////////
    /////// React INIT Hooks    ///////
    ///////////////////////////////////

    // User initialization hook
    // Need this boolean cause it fires twice for some reason
    let called = false
    useEffect(() => {
        // Function to be called on component initialization
        if (!called) {
            called = true
            // console.log("Initializing!")
            initializeFunction();
        }
    }, []);

    // Warn user on refresh
    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);


    const initializeFunction = async () => {
        try {
            setOverallFeedback('')
            setQuestionFeedback([])

            setPageLoaded(true)
            var intro = "Hello"
            if (name != "") {
                intro = "Hey " + name
            }

            const introText = intro + ", I'm CareerNinja! Welcome to your virtual interview. I will be asking you a few questions based on your resume and the job description. Please allow microphone and camera access if you haven't already, then select your interview duration.";
            setCurrentQuestion(introText)
            typePlayText(introText)
        } catch (e) {
            setIsErrorModalOpen(true)
        }

    };

    //////////////////////////////////
    /////// Timer feature  ///////////
    //////////////////////////////////

    useEffect(() => {
        const interval = setInterval(() => {
            // Calculate time remaining
            if (endTime) {
                const currentTime = new Date()
                const time_left = endTime - currentTime
                const minutesDifference = Math.floor(time_left / (1000 * 60));
                const secondsDifference = Math.floor((time_left % (1000 * 60)) / 1000);

                setMinutesLeft(minutesDifference + 1)

                // console.log(minutesDifference, secondsDifference)
            }

            // Calculate time that has passed
            if (startTime) {
                const currentTime = new Date()
                const timeDifference = currentTime.getTime() - startTime.getTime();
                const minutesPassed = Math.floor(timeDifference / (1000 * 60));
                const secondsPassed = Math.floor((timeDifference / 1000) % 60);
                setMinutesPassed(minutesPassed)
                setSecondsPassed(secondsPassed)

                // console.log(minutesPassed, secondsPassed)
            }


        }, 1000); // 1000 milliseconds = 1 second

        // Clean up the interval when the component is unmounted
        return () => {
            clearInterval(interval);
        };
    }, [endTime, startTime]);


    /////////////////////////////////////////////////////
    /////// HANDLE TTS, STT, AND TEXT DISPLAY    ////////
    /////////////////////////////////////////////////////

    // Types and plays audio for text
    const typePlayText = async (text, loadSpinner) => {
        if (!hasEnded) {
            var audioDuration = text.length * 30
            setCurrentText(text)

            try {
                const { audio, audioDurationInMS } = await textToSpeech(text);
                console.log('1')

                // Remove loading skeleton once speech is ready
                setLoadingMode(false);

                playAudio(audio)

                audioDuration = audioDurationInMS

                return new Promise(resolve => {
                    typeText(text, audioDuration, loadSpinner, resolve);
                });

            } catch (e) {

                console.log("Caught!")

                // If TTS failed, then type text without speech
                return new Promise(resolve => {
                    setLoadingMode(false);
                    typeText(text, null, loadSpinner, resolve)
                })
            }
        } else {
            return 0
        }
    }

    const typeText = async (text, total_duration, loadSpinner, resolve) => {
        var substring = ""
        var i = 0
        // By deafult, typeText will type at 30 ms/char
        var speed = 50

        if (total_duration) {
            var speed = total_duration / text.length
        }
        // Clear previous typeText instances
        clearInterval(intervalID)
        setHeadingText('')

        // Disable controls until done typing text
        // setDisableControls(true)

        // Enable the fast forward button, unless it's the end of an interview
        if (!loadSpinner) {
            setFastForwardAvailable(true)
        }


        var interval = setInterval(() => {
            // Check for loop exit condition and clear the interval when necessary
            if (i >= text.length) {
                if (loadSpinner) {
                    setActivateSpinner(true)
                }
                setFastForwardAvailable(false)
                clearInterval(interval);
                resolve()
            }

            substring += text.charAt(i)
            i += 1
            setHeadingText(substring)
        }, speed)

        setIntervalID(interval)
    };

    const handleSkipDialogue = () => {
        setDisableControls(false)
        clearInterval(intervalID);
        setFastForwardAvailable(false)
        setHeadingText(currentText)
        TTSAudio.pause()
    }

    function textToSpeech(text) {
        TTSAudio.pause()

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_SERVER_URL}/api/tts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ text: text })
            })
                .then(response => {
                    if (response.status === 429) {
                        setIsModalOpen(true);
                        throw new Error('TOO MANY REQUESTS');
                    } else if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    return response.json().then(data => {
                        let audio = new Audio(`data:audio/mp3;base64,${data.audio_content}`);
                        setTTSAudio(audio);
                        audio.addEventListener('loadedmetadata', function () {
                            var audioDurationInMS = audio.duration * 1000;
                            resolve({ audio, audioDurationInMS });
                        });
                    });
                })
                .catch(error => {
                    console.error("Error in textToSpeech:", error.message);
                    if (error.message.includes("TOO MANY REQUESTS")) {
                        setIsModalOpen(true);
                    }
                    reject(error);
                });
        });
    }

    const playAudio = (audio) => {
        if (!isMuted) {
            audio.volume = 1
            audio.play()

        } else {
            audio.volume = 0
            audio.play()
        }
    }

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition();

    if (!browserSupportsSpeechRecognition) {
        // gracefully handle browsers that don't support speech recognition here
        return <p>Your browser does not support speech recognition software. Try Chrome desktop browser instead.</p>
    }

    const startRecording = () => {
        resetTranscript();
        setIsRecording(true);
        setResponded(true)
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopRecording = () => {
        setIsRecording(false);

        SpeechRecognition.stopListening();
        setResponseText(transcript);
        resetTranscript();
    };

    //////////////////////////////////////////////////
    /////// HANDLE REACT UI BUTTONS AND PROPS ////////
    //////////////////////////////////////////////////

    const toggleInputMode = () => {
        setInputMode(!inputMode);
        setIsRecording(false)
    }

    const toggleMute = () => {
        setIsMuted(!isMuted)
        if (!isMuted) {
            TTSAudio.volume = 0
        } else {
            TTSAudio.volume = 1
        }
    }

    const delay = (delayInms) => {
        return new Promise(resolve => setTimeout(resolve, delayInms));
    }

    const setLoadingMode = (loading) => {
        if (loading) {
            TTSAudio.pause()
        }

        setIsLoading(loading)
    }

    /////////////////////////////////////////////////////////////
    /////// HANDLE MAIN INTERVIEW FUNCTIONS & API CALLS ////////
    ///////////////////////////////////////////////////////////

    const handleStart = async (duration) => {
        setFastForwardAvailable(false);
        setHasBegun(true);
        setHeadingText('');
        setLoadingMode(true);
        setDisableControls(true)

        try {
            const time = await axios.post(`${process.env.REACT_APP_SERVER_URL}/time`, {
                id: uuid,
                duration: duration
            });

            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/get_question`, {
                id: uuid,
            });

            const currentTime = new Date();
            const endTime = new Date(currentTime.getTime() + time.data.minutes * 60000);
            setStartTime(currentTime);
            setEndTime(endTime);
            setDuration(duration);
            setCurrentQuestion(response.data.question);

            await typePlayText(response.data.question);

        } catch (error) {
            console.error('Error fetching data:', error);

            // Check if error response exists and has the 429 status code
            if (error.response && error.response.status === 429) {
                setIsModalOpen(true);
            } else {
                setIsErrorModalOpen(true)
            }
        } finally {
            setDisableControls(false)
        }
    };

    const debug = async () => {
        const timing = await axios.post(`${process.env.REACT_APP_SERVER_URL}/time`, {
            id: uuid
        });

    }

    const handleSubmit = async () => {
        var answer;
        setHeadingText("");
        setLoadingMode(true);
        setHintActive(false);
        setDisableControls(true)

        var feedback, question, timing;

        try {
            feedback = await axios.post(`${process.env.REACT_APP_SERVER_URL}/response`, {
                id: uuid,
                question: currentQuestion,
                answer: responseText
            });

            setResponseText("");

            timing = await axios.post(`${process.env.REACT_APP_SERVER_URL}/time`, {
                id: uuid
            });

            if (timing.data.overtime) {
                const text = "Thanks for the response! Your time for the interview is now over. Please give me a moment while I generate your feedback";
                await handleEnd(text, false)
                return;
            }

            question = await axios.post(`${process.env.REACT_APP_SERVER_URL}/get_question`, {
                id: uuid,
            });

            setResponded(false);

        } catch (error) {
            console.error('Error fetching data:', error);

            // Check if error response exists and has the 429 status code
            if (error.response && error.response.status === 429) {
                setIsModalOpen(true);
            } else {
                setIsErrorModalOpen(true)
            }

            return; // Stop further execution if there was an error.
        }

        if (feedback && question && timing) {
            setCompletedQuestions(question.data.q_answered);
            setSkippedQuestions(question.data.skipped);

            if (!feedback.data.relevant) {
                var text = "I'm sorry, but the content you have provided appears to violate our usage policies. We cannot accept that response and will move on to the next question.";
                await typePlayText(text);

            }

            setCurrentQuestion(question.data.question);
            await typePlayText(question.data.question);
            setDisableControls(false)
        }
    }

    const handleSkip = async () => {
        setHeadingText("");
        setHintActive(false);
        setLoadingMode(true);
        setDisableControls(true)

        try {
            var timing = await axios.post(`${process.env.REACT_APP_SERVER_URL}/time`, {
                id: uuid
            });

            if (timing.data.overtime) {
                const text = "It appears your interview has finished, so you will not be receiving another question. Please give me a moment while I generate your feedback";
                await handleEnd(text, true)
                return;
            }

            const skipResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/skip`, {
                id: uuid,
                question: currentQuestion
            });

            const question = await axios.post(`${process.env.REACT_APP_SERVER_URL}/get_question`, {
                id: uuid,
            });

            setResponded(false);
            setCompletedQuestions(question.data.q_answered);
            setSkippedQuestions(question.data.skipped);
            setCurrentQuestion(question.data.question);
            await typePlayText(question.data.question);
        } catch (error) {
            console.error('Error fetching data:', error);

            // Check if error response exists and has the 429 status code
            if (error.response && error.response.status === 429) {
                setIsModalOpen(true);
            } else {
                setIsErrorModalOpen(true)
            }

        } finally {
            setDisableControls(false)
        }
    }


    const handleHint = async () => {
        setDisableControls(true)
        setLoadingMode(true);
        setHeadingText("");

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/hint`, {
                id: uuid,
                question: currentQuestion
            });

            setHintActive(true);
            await typePlayText(response.data.hint);
        } catch (error) {
            console.error('Error fetching data:', error);

            // Check if error response exists and has the 429 status code
            if (error.response && error.response.status === 429) {
                setIsModalOpen(true);
            } else {
                setIsErrorModalOpen(true)
            }

        } finally {
            setDisableControls(false)
        }
    }

    // Used by skip overtime, end button, response overtime
    const handleEnd = async (text, generate_final_sample) => {
        setHasEnded(true)
        setHideControls(true)
        onClose();                                         // Close modal on mobile if it's open.
        const textPromise = typePlayText(text, true)  // loadSpinner = true
        // await delay(time_delay)

        try {
            if (generate_final_sample) {
                await axios.post(`${process.env.REACT_APP_SERVER_URL}/skip`, {
                    id: uuid,
                    question: currentQuestion
                });
            }

            const history = await axios.post(`${process.env.REACT_APP_SERVER_URL}/get_history`, {
                id: uuid,
            });

            await textPromise // Make sure text is fully done typing before moving on
            setQuestionFeedback(history.data.interview_history)
            setOverallFeedback(history.data.overall_feedback)

            await delay(1000)

            navigate('/feedback')
        } catch (e) {
            setIsErrorModalOpen(true)
        }
    }




    return (
        <Container
            height='100vh'
            width='100vw'
            display="flex"
            flexDirection="column"
            alignItems='center'
            transition="opacity .7s ease"
            opacity={pageLoaded ? 1 : 0}
        >
            <Flex width='95vw' mt='2' mb='4' height=''>
                <Flex alignItems='start' mt='2'>
                    {isMobile ? (<></>) : (
                        <Image height="2.5rem" src='/ninja.png' objectFit='cover' me='2' />
                    )}
                    <a href={process.env.REACT_APP_SERVER_URL}>
                        <HStack alignItems='center' justifyContent='center' display='flex' ms={isMobile ? '2' : '0'}>
                            <Heading size='lg' pb='1' bgColor={colorMode == 'dark' ? 'white' : 'black'} bgClip="text">CareerNinja</Heading>

                            <Badge fontSize='1rem' colorScheme='green'>
                                .AI
                            </Badge>
                        </HStack>
                    </a>
                </Flex>

                <Spacer />

                <Box display="flex"
                    justifyContent="center"
                    alignItems='center' >
                    <ButtonGroup variant='' spacing='2'>
                        {!isMobile ? (<Button leftIcon={isMuted ? <BsFillVolumeMuteFill /> : <BsFillVolumeUpFill />} size='sm' variant='solid' onClick={toggleMute}>
                            {isMuted ? "Unmute" : "Mute Audio"}
                        </Button>) : (<></>)}

                        {!isMobile ? (<Button leftIcon={inputMode ? <BsFillWebcamFill /> : <BsFillPencilFill />} size='sm' variant='solid' onClick={toggleInputMode}>
                            {inputMode ? 'Video Response' : 'Text-Only Response'}
                        </Button>) : (<Button leftIcon={inputMode ? <BsFillWebcamFill /> : <BsFillPencilFill />} size='sm' variant='solid' onClick={toggleInputMode}>
                            {inputMode ? 'Video Reply' : 'Text Reply'}
                        </Button>)}

                        {!isMobile ? (<Button leftIcon={colorMode == 'light' ? <MoonIcon /> : <SunIcon />} size='sm' variant='solid' onClick={toggleColorMode}>
                            {colorMode == 'light' ? "Dark Mode" : "Light Mode"}
                        </Button>) : (<IconButton onClick={toggleColorMode} size='sm' variant='solid' icon={colorMode == 'light' ? <MoonIcon /> : <SunIcon />} />)}

                    </ButtonGroup>
                </Box>
            </Flex>


            <Box height={{ base: '80vh', md: '85vh' }} width={{ base: '95vw', xl: '90vw' }} backgroundColor={colorMode == 'dark' ? 'gray.900' : 'gray.100'} alignItems='center' position='relative' justifyContent="center" mb='5' boxShadow={colorMode == 'dark' ? 'dark-lg' : ''} rounded='xl' >
                <Box height='100%' width='100%' style={{
                    backgroundImage: `url(/camera-off.svg)`, // Assuming the SVG file is named 'background.svg'
                    backgroundSize: '10%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    borderRadius: '15px',
                }}>

                    <Box height='100%' width='100%' >
                        {inputMode ? (<></>) : (<Webcam
                            audio={false} // Set to false if you don't need audio
                            mirrored={true}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '15px' }}
                        />)}
                    </Box>
                </Box>

                <Flex transition="opacity 1s ease" opacity={hasBegun && !hideControls ? 1 : 0} position="absolute" top={4} left={4} direction="column" align="flex-start">
                    {!isMedium ? (<ProgressCard completedQuestions={completedQuestions} skippedQuestions={skippedQuestions} handleEnd={handleEnd} minutesPassed={minutesPassed} secondsPassed={secondsPassed} duration={duration} isLoading={isLoading} disableControls={disableControls} />) : (<></>)}
                </Flex>

                <Flex position="absolute" top={3} justifyContent="center" alignItems="center" left="50%" transform="translateX(-50%)" direction="column">
                    <DialogBox hasBegun={hasBegun} intervalID={intervalID} hintActive={hintActive} handleStart={handleStart} headingText={headingText} isLoading={isLoading} activateSpinner={activateSpinner} setHintActive={setHintActive} setHeadingText={setHeadingText} currentQuestion={currentQuestion} TTSAudio={TTSAudio} setDisableControls={setDisableControls} fastForwardAvailable={fastForwardAvailable} handleSkipDialogue={handleSkipDialogue} />
                </Flex>

                <Flex position="absolute" bottom={3} justifyContent="center" alignItems="center" left="50%" transform="translateX(-50%)" transition="opacity 1s ease" opacity={hasBegun && !hideControls ? 1 : 0}>
                    <Controls isLoading={isLoading} inputMode={inputMode} handleSubmit={handleSubmit} isRecording={isRecording} stopRecording={stopRecording} startRecording={startRecording} handleSkip={handleSkip} handleHint={handleHint} endTime={endTime} responseText={responseText} setResponseText={setResponseText} minutesLeft={minutesLeft} transcript={transcript} responded={responded} disableControls={disableControls} />
                </Flex>


                <Flex position="absolute" bottom={3} justifyContent="center" alignItems="center" left="50%" transform="translateX(-50%)" width={{ base: '80vw', md: '29rem' }} display={hasBegun ? 'none' : ''} shadow='lg'>
                    <Center width='100%' textAlign='center' >
                        <Alert status='info' bgGradient={colorMode == 'dark' ? "linear(to-r, gray.600, gray.700)" : "linear(to-r, gray.50, gray.50)"} opacity={1} width='100%' >
                            <AlertIcon />
                            You may end an interview early in the "Progress" menu
                        </Alert>
                    </Center>

                </Flex>
            </Box>


            <Flex align="center" >
                {!isMedium ? (<Text textAlign="center" fontSize="sm" >
                    Mock Interview Mode
                </Text>) :
                    (<ProgressCardMobile completedQuestions={completedQuestions} skippedQuestions={skippedQuestions} handleEnd={handleEnd} minutesPassed={minutesPassed} secondsPassed={secondsPassed} duration={duration} isLoading={isLoading} isOpen={isOpen} onOpen={onOpen} onClose={onClose} disableControls={disableControls} />)}
            </Flex>

            <Modal isOpen={isModalOpen} isCloseable={false} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>API Limit Reached!</ModalHeader>

                    <ModalBody>
                        <Text>You have reached your API call limit. Please try again later or contact support.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={returnHome}>Return Home</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={minutesLeft != -999 && minutesLeft <= -15} isCloseable={false} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>Time is up!</ModalHeader>

                    <ModalBody>
                        <Text>You have exceeded your interview duration by 15 minutes, please return home if you would like to start another interview. </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={returnHome}>Return Home</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isErrorModalOpen} isCloseable={false} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent >
                    <ModalHeader>Server Error</ModalHeader>
                    <ModalBody mb='3'>
                        <Text>CareerNinja had trouble communicating with the server. Please try again later or contact support</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={returnHome}>Return Home</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>




        </Container >
    );
}


export default InterviewPageV2;