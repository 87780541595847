import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    useColorMode,
    useSteps,
    Spinner,
    Tooltip,
    Card, CardHeader, CardBody, CardFooter, VStack
} from "@chakra-ui/react";



import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsUpload } from 'react-icons/bs'




function SelectionCard(props) {
    const { ico, heading, subHeading, clickFunction, disabled, spinnerActive } = props

    const { colorMode, toggleColorMode } = useColorMode();


    let called = false
    useEffect(() => {
        // Function to be called on component initialization
        if (!called) {

        }
    }, []);


    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [isHovered, setIsHovered] = useState(false);


    return (
        <Tooltip label='Retrieving data from server...' display={spinnerActive ? '' : 'none'}       >
            <Card
                shadow="lg"
                opacity={disabled ? 0.7 : 1}
                width={{ base: "80vw", md: '25rem', lg: '20rem' }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}

                style={disabled ? { position: "relative", cursor: "not-allowed" } : { position: "relative", cursor: "pointer" }}
                onClick={clickFunction}
            >
                <CardBody>
                    <Flex alignItems="center">
                        <IconButton colorScheme="green" icon={ico} me="4" size="md" />
                        <VStack alignItems="start" spacing="0">
                            <Heading
                                as="h4"
                                size="md"
                                color={colorMode == 'dark' ? isHovered ? "green.200" : "white" : isHovered ? "green.500" : "black"
                                } // Change color on hover
                            >
                                {heading}
                            </Heading>
                            <Text fontSize="md">{subHeading}</Text>
                        </VStack>
                        <Spacer />
                        <Spinner display={spinnerActive ? '' : 'none'} />
                    </Flex>
                </CardBody>
            </Card>
        </Tooltip>

    );
}

export default SelectionCard;