import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container, useBreakpointValue, AbsoluteCenter, Icon, HStack, Badge, Spacer, Collapse } from '@chakra-ui/react';

import { ArrowBackIcon, SunIcon, MoonIcon, ArrowForwardIcon, CloseIcon, DownloadIcon } from '@chakra-ui/icons';
import { BsArrowReturnLeft, BsArrow90DegLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import FeedbackBox from "./FeedbackBox";


import { FaStar, FaRegStar } from 'react-icons/fa';


import {
    Box,
    Stack,
    Image,
    Button,
    Skeleton,
    Center,
    Heading,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Text,
    VStack,
    StackDivider,
    Highlight,
    Divider,
    Flex,
    useColorMode,
    IconButton,
    Textarea,

} from "@chakra-ui/react";

import axios from 'axios';


function FeedbackPage(props) {
    const { name, overallFeedback, questionFeedback, setOverallFeedback, setQuestionFeedback, uuid } = props
    const { colorMode, toggleColorMode, setColorMode } = useColorMode();

    const isMobile = useBreakpointValue({ base: true, md: false });
    const navigate = useNavigate();
    const [pageLoaded, setPageLoaded] = useState(false)


    const [selectedStars, setSelectedStars] = useState(false)
    const [successfulServerReq, setSuccessfulServerReq] = useState(false)
    const [submittedUserFeedback, setSubmittedUserFeedback] = useState(false)
    const [userFeedbackClosed, setUserFeedbackClosed] = useState(false)
    const [invalidInput, setInvalidInput] = useState(false)

    const [rating, setRating] = useState(0);

    let [value, setValue] = React.useState('')

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setValue(inputValue)
    }

    const returnHome = async () => {

        navigate('/industry');
    }

    // Debugging tool wired to 'light mode' button
    const debug = () => {
        // Add dummy responses for testing
        setOverallFeedback("Overall, your interview performance was excellent. You presented your experiences and project challenges with a well - structured and clear approach, demonstrating your technical competence. However, there were opportunities to provide deeper elaboration in certain areas, particularly regarding the technical steps taken. Overall, your interview performance was excellent. You presented your experiences and project challenges with a well - structured and clear approach, demonstrating your technical competence. However, there were opportunities to provide deeper elaboration in certain areas, particularly regarding the technical steps taken.")

        setQuestionFeedback([{ answer: "One of the primary challenges we faced was dealing with performance issues, especially on older Android devices. Initially, the app was running smoothly on the latest smartphones, but it was sluggish on devices with lower hardware specifications. This was a setback because we wanted to target a broad user base. ", feedback_or_sample: "The candidate provided a concise and clear answer to the question. They mentioned their experience in using Python and C++ in both their classes and internships, which demonstrates their exposure to multiple programming languages. This response indicates that the candidate meets the minimum qualifications of having experience in coding in multiple programming languages. However, it would be beneficial to ask follow-up questions to further explore their proficiency and projects in these languages.", question: "That sounds like a very interesting project! What challenges or setbacks did you face during the process, and how did you overcome them?", questionNumber: 0 },])

        console.log(overallFeedback)
        console.log(questionFeedback)
    }

    let called = false
    // Temporary init hook. Find a better one in the future! Will fire twice if !called check is removed.
    useEffect(() => {
        // Function to be called on component initialization
        if (!called) {
            called = true
            setPageLoaded(true)

            // if (overallFeedback == "") {
            //     navigate('/')
            // }
        }
    }, []); // Empty dependency array ensures the effect runs only once


    const handleSetStarRating = async (ratingValue) => {
        setRating(ratingValue)
        setSelectedStars(true)

        await axios.post(`${process.env.REACT_APP_SERVER_URL}/submitStarRating`, {
            id: uuid,
            star_rating: ratingValue
        });

        setSuccessfulServerReq(true)
    }

    const handleUserFeedbackInput = () => {
        if (value) {

            setSubmittedUserFeedback(true)
            axios.post(`${process.env.REACT_APP_SERVER_URL}/submitUserFeedback`, {
                id: uuid,
                user_feedback: value
            });
        } else {
            setInvalidInput(true)
        }
    }

    const handleDownload = () => {
        const initialColorMode = colorMode
        setColorMode('light')
        window.print()
        setColorMode(initialColorMode)
    }




    return (
        <Container
            minHeight='100vh'
            width='100vw'
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems='center'
            transition="opacity .7s ease"
            opacity={pageLoaded ? 1 : 0}
        >

            <Box
                position="fixed"
                top="0"
                left="0"
                right="0"
                zIndex="999" // Adjust the z-index as needed
                backgroundColor={colorMode == 'dark' ? "rgba(26, 32, 44, .9)" : "rgba(255, 255, 255, .9)"}
            >
                <Center mt='5' mb='5'>
                    <Flex width={{ base: '90vw', xl: '80vw' }}>
                        <a href={process.env.REACT_APP_SERVER_URL}>
                            <Flex alignItems='start'>
                                <Image height="2.5rem" src='/ninja.png' objectFit='cover' me='2' />
                                <HStack alignItems='center' justifyContent='center' display='flex'>
                                    <Heading size='lg' pb='1' bgColor={'white'} bgClip="text" textColor={colorMode == 'dark' ? 'white' : 'black'}>CareerNinja</Heading>

                                    <Badge fontSize='1rem' colorScheme='green'>
                                        .AI
                                    </Badge>

                                </HStack>
                            </Flex>
                        </a >


                        <Spacer />
                        <Box>
                            <HStack spacing={3}>

                                {<Button size='md' variant='solid' colorScheme="green" onClick={returnHome} display={isMobile ? 'none' : ''}>
                                    Take another interview
                                </Button>}

                                {!isMobile ? (<Button leftIcon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />} size='md' variant='solid' onClick={toggleColorMode}>
                                    {colorMode == 'dark' ? "Light Mode" : "Dark Mode"}
                                </Button>) : (<IconButton onClick={toggleColorMode} size='md' variant='solid' icon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />} />)}
                            </HStack>

                        </Box>

                    </Flex>
                </Center >
            </Box>


            <Center display="flex"
                flexDirection="column">
                <Box width='100vw' height={{ base: '6rem', lg: '8rem' }} backgroundColor=''>

                </Box>


                <Box width={{ base: '85vw', md: '40rem', lg: '50rem' }} height='' backgroundColor='' mb='2' mt='12' >

                    <Heading as='h2' size='xl' textAlign='center'>
                        Here's some feedback from your interview.
                    </Heading>
                    <Box maxHeight='8rem' overflow='auto' mt='7' >
                        <Text textAlign='center' fontSize='lg' height='100%'>
                            {overallFeedback}</Text>
                    </Box>

                </Box>

                {<Button size='md' variant='solid' textColor='green.100' onClick={returnHome} display={isMobile ? '' : 'none'} mt='5'>
                    Take another interview
                </Button>}

                <Divider width={{ base: '90vw', md: '45rem', lg: '55rem' }} mt='8' />

                <Collapse in={!submittedUserFeedback}>
                    <Collapse in={!userFeedbackClosed}>
                        <Box alignItems='center' justifyContent='center' flexDirection='column' display='flex' p='6' rounded='lg' mb='2' position='relative' width={{ base: '90vw', md: '45rem', lg: '55rem' }}>

                            <IconButton position="absolute" top='2' right='2' size='sm' variant="ghost" icon={<CloseIcon />} onClick={() => setUserFeedbackClosed(true)} />

                            <Heading size='lg' mb='4' fontWeight='normal' textAlign='center'>
                                How would you rate CareerNinja?
                            </Heading>
                            {/* If the star rating gets successful sent to the server, gray it out */}
                            <Box mb='5' opacity={successfulServerReq ? '.7' : '1'}>
                                {[...Array(5)].map((star, index) => {
                                    const ratingValue = index + 1;

                                    return (
                                        <label key={index}>
                                            <input
                                                type="radio"
                                                name="rating"
                                                value={ratingValue}
                                                onClick={selectedStars ? () => null : () => handleSetStarRating(ratingValue)}
                                                style={{ display: 'none' }}
                                            />
                                            <Icon
                                                as={ratingValue <= rating ? FaStar : FaRegStar}
                                                color={ratingValue <= rating ? 'yellow.400' : 'gray.300'}
                                                w={6}
                                                h={6}
                                                cursor="pointer"
                                                ml={1}
                                            />
                                        </label>
                                    );
                                })}
                            </Box>

                            <Flex alignItems='center' width='60%'>
                                <Textarea
                                    value={value}
                                    rounded='lg'
                                    resize='none'
                                    height='5rem'
                                    onChange={handleInputChange}
                                    placeholder='Let us know your feedback'
                                    size='sm'
                                    isInvalid={invalidInput}
                                    me={isMobile ? '0' : '3'}
                                />

                                {isMobile ? (<></>) : (

                                    <IconButton icon={<ArrowForwardIcon />} variant='outline' isRound={true} onClick={handleUserFeedbackInput} />
                                )}
                            </Flex>

                            {isMobile ? (

                                <Button rightIcon={<ArrowForwardIcon />} colorScheme='green' variant='outline' mt='3' onClick={handleUserFeedbackInput}>
                                    Submit
                                </Button>
                            ) : (

                                <></>
                            )}


                        </Box>
                    </Collapse>
                </Collapse>

                <Collapse in={submittedUserFeedback}>
                    <Heading fontWeight='normal' size='lg' my='12'>Thanks for the feedback!</Heading>
                </Collapse>

                <Divider width={{ base: '90vw', md: '45rem', lg: '55rem' }} mb='10' />




                <Box width={{ base: '90vw', md: '45rem', lg: '55rem' }} height='' mb='8' bgGradient={colorMode == 'dark' ? "linear(to-r, gray.600, gray.700)" : "linear(to-r, gray.100, gray.300)"} rounded='lg' boxShadow='md' display={questionFeedback.length > 0 ? '' : 'none'} position='relative' >
                    <IconButton position="absolute" top='7' right='5' size='md' variant="ghost" icon={<DownloadIcon />} onClick={handleDownload} />
                    <Heading ms='7' mt='8' size='md' fontWeight='semibold' width={isMobile ? '60vw' : ''}>Question-by-Question Analysis</Heading>


                    <VStack
                        divider={<StackDivider borderColor={colorMode == 'dark' ? 'gray.400' : 'gray.400'} />}
                        spacing={0}
                        align='stretch'
                        mt='0'
                    >
                        {questionFeedback.map((question, index) => (
                            (
                                <FeedbackBox
                                    key={index}
                                    questionNumber={'Question ' + (index + 1).toString()} // Don't forget to add a unique key prop if your questions have a unique identifier (e.g., an 'id')
                                    question={question.question}
                                    response={question.answer}
                                    feedback={question.feedback_or_sample}
                                />)
                        ))}

                    </VStack>
                </Box>

            </Center>

        </Container >
    )


}

export default FeedbackPage