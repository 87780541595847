import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container, useBreakpointValue, AbsoluteCenter } from '@chakra-ui/react';

import { ArrowBackIcon, SunIcon } from '@chakra-ui/icons';
import { BsArrowReturnLeft, BsArrow90DegLeft } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

import CursorSVG from "./CursorSVG";

import {
    Box,
    Stack,
    Image,
    Button,
    Skeleton,
    Center,
    Heading,
    Tabs,
    TabList,
    TabPanels,
    TabPanel,
    Tab,
    Text,
    VStack,
    StackDivider,
    Highlight,
    Flex
} from "@chakra-ui/react";

function FeedbackBox(props) {
    const { questionNumber, question, response, feedback } = props

    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box minH='7rem' bg='' m='7'>

            <Text textAlign='' fontSize='lg' mb='3'>

                <Highlight
                    query={questionNumber}
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.200' }}
                >
                    {questionNumber}
                </Highlight>
            </Text>
            <Text textAlign='' fontSize='lg' mb='6'>
                {question}
            </Text>
            <Text textAlign='' fontSize='lg' mb='3'>
                <Highlight
                    query='Your Response:'
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.200' }}
                >
                    Your Response:
                </Highlight>
            </Text>
            <Text textAlign='' fontSize='lg' mb='6'>
                {response ? response : "You decided to skip this question or ended the interview before providing an answer. Look at the section below for a possible answer you could have given."}
            </Text>

            <Text textAlign='' fontSize='lg' mb='3'>
                <Highlight
                    query={response ? "Feedback:" : "Sample response:"}
                    styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.200' }}
                >
                    {response ? "Feedback:" : "Sample response:"}
                </Highlight>
            </Text>
            <Text textAlign='' fontSize='lg' mb='1'>
                {feedback ? feedback : "CareerNinja was not able to provide a sample response for this question"}
            </Text>


        </Box >
    )
}

export default FeedbackBox