import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    Image,
    useColorMode,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react";

import axios from 'axios';

import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsUpload } from 'react-icons/bs'
import { MdOutlineContentCopy, MdOutlineContactPage } from 'react-icons/md'

import { useNavigate } from 'react-router-dom';


import SelectionCard from "../SelectionCard";



function CopyAndPasteModal(props) {

    const { copyPasteIsOpen, openCopyPaste, closeCopyPaste, setResumeText, resumeText, setJobDescriptionText, jobDescriptionText, uuid, inputType, setResumeAdequateInfo, setJobDescriptionAdequateInfo, practiceOption, startInterview } = props
    const navigate = useNavigate();
    const { colorMode, toggleColorMode } = useColorMode();


    let called = false
    useEffect(() => {
        // Function to be called on component initialization
        if (!called) {

        }
    }, []);



    const handleJobSubmit = async () => {
        if (jobDescriptionText.length > 10) {
            startInterview()
        } else {
            setJobDescriptionAdequateInfo(false)
        }
    }

    const handleResumeSubmit = () => {
        if (resumeText.length < 10) {
            setResumeAdequateInfo(false)
        } else if (practiceOption == "Upcoming interview") {
            navigate('/jobdescription')
        } else {
            startInterview()
        }
    }

    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, lg: false });


    return (
        <div>

            <Modal onClose={closeCopyPaste} isOpen={copyPasteIsOpen} isCentered >
                <ModalOverlay zIndex='10' />
                <ModalContent minWidth={{ base: '90vw', md: '45rem' }} maxWidth={{ base: '90vw', md: '45rem' }} zIndex='15'>
                    <ModalHeader pb='2'>Copy & Paste</ModalHeader>
                    <Text ms='6' mb='5' maxW='80vw'>Note: Our systems don't require formatting </Text>
                    <ModalCloseButton />
                    <ModalBody>
                        {inputType == "resume" ?
                            (
                                <Textarea placeholder='Paste your resume here' height='60vh' onChange={(e) => setResumeText(e.target.value)} />
                            )
                            :
                            (
                                <Textarea placeholder='Paste your job description here' height='60vh' onChange={(e) => setJobDescriptionText(e.target.value)} />
                            )
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button me='2' onClick={closeCopyPaste} >Cancel</Button>

                        {inputType == "resume" ?
                            (
                                <Button onClick={handleResumeSubmit} colorScheme="blue">Continue</Button>
                            )
                            :
                            (
                                <Button onClick={handleJobSubmit} colorScheme="blue">Continue</Button>
                            )
                        }

                    </ModalFooter>
                </ModalContent>
            </Modal>


        </div >
    );
}

export default CopyAndPasteModal;