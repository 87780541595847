import React, { useState, useRef, useEffect } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    Image,
    useColorMode,
    HStack,
    Badge,
    Alert,
    AlertIcon,
    useDisclosure,
    VStack,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Stat,
    StatLabel,
    StatNumber,
    keyframes,
} from "@chakra-ui/react";



import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsUpload, BsArrowUpRight } from 'react-icons/bs'
import { FiArrowUpRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf';
import AboutUsModal from "./AboutUsModal";

import AOS from "aos";
import "aos/dist/aos.css";

// for Google Analytics
import ReactGA from "react-ga4";
import { color } from "framer-motion";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Generate a new UUID
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}


function IntroPage(props) {
    const { uuid, setName, resumeText, setResumeText, jobDescriptionText, setJobDescriptionText, TTSAudio } = props
    const navigate = useNavigate();

    const { colorMode, toggleColorMode, setColorMode } = useColorMode();
    const [resumeAdequateInfo, setResumeAdequateInfo] = useState(true)
    const [jobDescriptionAdequateInfo, setJobDescriptionAdequateInfo] = useState(true)

    const [pageLoaded, setPageLoaded] = useState(false)

    // For info modal
    const { isOpen, onOpen, onClose } = useDisclosure()

    const minResumeCharacters = 30
    const minJobDescriptionCharacters = 10

    // Initialization function. We need this to turn off TTS if a user goes from the /interview route to /intropage
    let called = false
    useEffect(() => {
        // Function to be called on component initialization

        if (!called) {
            setColorMode('dark')

            AOS.init({ duration: 1000 });
            setTimeout(() => { AOS.refresh(); }, 500);

            // Generate or retrieve unique ID
            let uniqueID = localStorage.getItem('uniqueID');
            if (!uniqueID) {
                uniqueID = generateUUID();
                localStorage.setItem('uniqueID', uniqueID);
            }

            called = true
            // console.log("Initializing!")
            setPageLoaded(true)

            TTSAudio.pause()
        }
    }, []);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file.type !== "application/pdf") {
            console.error('Please upload a PDF file.');
            return;
        }

        const reader = new FileReader();
        reader.onload = (event) => {
            const pdfData = new Uint8Array(event.target.result);

            pdfjs.getDocument({ data: pdfData }).promise.then((pdf) => {
                let textPromises = [];
                for (let i = 1; i <= pdf.numPages; i++) {
                    textPromises.push(pdf.getPage(i).then(page => {
                        return page.getTextContent();
                    }).then(content => {
                        return content.items.map(item => item.str).join(' ');
                    }));
                }

                Promise.all(textPromises).then(textPages => {
                    setResumeText(textPages.join('\n'));
                });
            });
        }

        reader.readAsArrayBuffer(file);

        // Reset the input value after processing the file
        event.target.value = null;
    }


    const handleContinue = async () => {
        if (jobDescriptionText.length >= minJobDescriptionCharacters && resumeText.length >= minResumeCharacters) {
            navigate('/interview');

            try {
                const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/initialize`, {
                    id: uuid,
                    resume: resumeText,
                    job_description: jobDescriptionText
                });

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setJobDescriptionAdequateInfo(jobDescriptionText.length > minJobDescriptionCharacters)
            setResumeAdequateInfo(resumeText.length > minResumeCharacters)
        }

        // Track "Start Interview" button
        ReactGA.event({
            category: 'User',
            action: 'Clicked Button',
            label: 'Start Interview Button'
        });
    }


    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    }

    // const images = ['/carousel/google.svg', '/carousel/meta.svg', '/carousel/tesla.svg', '/carousel/apple.svg', '/carousel/amazon.svg', '/carousel/paypal.svg', '/carousel/netflix.svg', '/carousel/microsoft.svg', '/carousel/nvidia.svg']
    const images = ['/carousel/google.png', '/carousel/meta.png', '/carousel/tesla.png', '/carousel/apple.png', '/carousel/amazon.png', '/carousel/netflix.png', '/carousel/microsoft.png', '/carousel/X.png']

    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = "linear(to-r, green.200, green.400)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isDesktop = useBreakpointValue({ base: false, lg: true });
    const isDark = colorMode == 'dark'

    const text1 = "Just like a real interview, say your responses out loud. No microphone? No problem — you can type too."
    const text2 = "Every response is remembered by CareerNinja's Artificial Intelligence. Questions are generated with context to provide an immersive experience."
    const text3 = "Once you complete your interview, CareerNinja uses AI to provide you feedback on your responses, so you know exactly what to work on before the real thing."

    const scroll = keyframes({
        "0%": { left: "0" },
        "100%": { left: "-100%" }
    });

    return (
        <div>

            <Flex
                minHeight="100vh"
                maxW='100vw'
                overflowX="hidden"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                alignItems='center'
                backgroundColor={"gray.800"}
            // position='relative'
            >

                <AboutUsModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />


                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    right="0"
                    zIndex="999" // Adjust the z-index as needed
                    backgroundColor="rgba(26, 32, 44, .90)"
                    opacity='20'
                >
                    <Center mt='5' mb='5'>
                        <Flex width={{ base: '90vw', xl: '80vw' }}>
                            <Flex alignItems='start'>
                                <Image height="2.5rem" src='/ninja.png' objectFit='cover' me='2' />
                                <HStack alignItems='center' justifyContent='center' display='flex'>
                                    <Heading size='lg' pb='1' bgColor={'white'} bgClip="text">CareerNinja</Heading>

                                    <Badge fontSize='1rem' colorScheme='green'>
                                        .AI
                                    </Badge>

                                </HStack>
                            </Flex>


                            <Spacer />
                            <Box>
                                {!isMobile ? (<Button variant='outline' leftIcon={<InfoOutlineIcon />} me='3' onClick={onOpen}> About</Button>) : (<IconButton me='2' onClick={onOpen} icon={<InfoOutlineIcon />} />)}

                            </Box>

                        </Flex>
                    </Center >
                </Box>

                <Box height={isMobile ? '6rem' : '8rem'}>

                </Box>

                <Flex display="flex" flexDirection="column" alignItems='center' transition="opacity 1s ease" opacity={pageLoaded ? 1 : 0}>
                    <Box borderRadius='2rem' border={'1px solid #48BB78'} alignItems='center' justifyContent='center' display='flex' px='5' py='1.5' maxW='80vw'>

                        <Text fontSize={isMobile ? 'sm' : 'md'} textAlign='center' textColor={'gray.200'}>&#x1F389;&nbsp; CareerNinja has been redesigned and is easier to use than ever!</Text>

                    </Box>
                    <Box height={isMobile ? '1.5rem' : '2.4rem'}></Box>

                    <Center axis='both' pb='0' boxShadow='' height='' alignItems='center' justifyContent='center' display='flex'  >
                        <Box textAlign="center" alignItems='center' justifyContent='center' position="relative" top="" width={{ base: '90vw', md: '45rem', lg: '60rem' }}>
                            <Center>
                                <Heading width='100%' size={isMobile ? '3xl' : '3xl'} pb={isMobile ? '6' : '10'} bgClip='text' bgGradient={gradientColors} fontWeight='bold' > {!isMobile ? "Prepare for your interviews" : "Prepare for interviews"}  <br></br> using <span style={{ color: '#38B2AC' }}>AI</span></Heading>

                                {/* <Box textStyle='mainHeading' width='100%' fontWeight>Prepare for any interivew using AI</Box> */}
                                {/* Prepare for SWE behavorial interviews using AI */}
                            </Center>
                            <Center>
                                <Text width={isMobile ? '90%' : '80%'} textColor={'gray.200'} fontSize={isMobile ? 'lg' : 'xl'} mb={10}>Build confidence in your interviewing skills with CareerNinja, an AI-powered site providing virtual interviews based on your resume and job description. </Text>
                            </Center>
                        </Box>
                    </Center>
                    <Box height='' >
                        <Button colorScheme='green' onClick={() => navigate('/industry')} size='lg'>
                            Try it out for free
                        </Button>
                    </Box>
                    <Text fontSize='md' mt='3' textColor='gray.300'>No signup required </Text>
                    <Box height={{ base: '3rem', lg: '4.5rem' }}>

                    </Box>

                    {/* https://stock.adobe.com/search?k=macbook+pro&asset_id=527286707 */}
                    <Image width={{ base: '80vw', md: '43rem', lg: '55rem' }} src='/screenshot.png' objectFit='cover' me='2' />

                    <Box height={isMobile ? '10vh' : '15vh'}>
                    </Box>

                </Flex>

                <Heading size={isMobile ? 'sm' : 'md'} width='90vw' textAlign='center' mb={isMobile ? '5' : '6'} fontWeight='semibold'>
                    Practice for interviews for any company:
                </Heading>
                <Box
                    w={{ base: '35rem', md: '60rem', lg: '80rem' }}
                    h={isMobile ? '4rem' : '6rem'}
                    // borderTop="1px"
                    // borderBottom="1px"
                    // borderColor="gray.600"
                    overflow="hidden"
                    position="relative"
                    alignItems='center'
                    justifyContent='center'
                    display='flex'
                >
                    <Box
                        w="200%"
                        display="flex"
                        alignItems="center"
                        h="70%"
                        justifyContent="space-around"
                        position="absolute"
                        left="0"
                        animation={`${scroll} 15s linear infinite`}
                        gap="1rem"
                        opacity='70%'
                    >
                        {images.map((i, index) => (
                            <Box
                                key={index}
                                display="flex"
                                justifyContent="center"
                                alignItems="start"
                                h='80%'
                            >
                                <Image h='100%' src={i} alt={`slider-image-${index}`} />
                            </Box>
                        ))}
                        {images.map((i, index) => (
                            <Box
                                key={index + images.length}
                                display="flex"
                                justifyContent="center"
                                alignItems="start"
                                h='80%'

                            >
                                <Image h='100%' src={i} alt={`slider-image-${index}`} />
                            </Box>
                        ))}
                    </Box>
                    {/* Gradient Overlays */}
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        bottom="0"
                        w="10%"
                        bgGradient="linear(to-r, gray.800, transparent)"
                        pointerEvents="none" // Make sure the overlay does not interfere with any potential interactions with the slider
                    />
                    <Box
                        position="absolute"
                        top="0"
                        right="0"
                        bottom="0"
                        w="10%"
                        bgGradient="linear(to-l, gray.800, transparent)"
                        pointerEvents="none"
                    />

                </Box>
                <Box height='4rem'>

                </Box>



                <Center>
                    {/* <Flex position='relative' bottom={4}>
                        <Text fontSize='sm'>
                            © 2023 CareerNinja. All Rights Reserved.
                        </Text>
                    </Flex> */}
                </Center>
            </Flex >








            <Flex
                minHeight={isMobile ? "90vh" : "100vh"}
                display="flex"
                flexDirection="column"
                justifyContent="start"
                alignItems='center' // This centers the child vertically
                backgroundColor={"gray.900"}
            >

                <Box height={isMobile ? '6rem' : '9rem'}>

                </Box>


                <Flex data-aos="fade-up" display="flex" flexDirection="column" alignItems='center'>

                    <Heading textAlign='center' width='90vw' size={{ base: '2xl', lg: '2xl' }} pb='6    ' color={"gray.200"}>Take an <span style={{ color: '#9AE6B4' }}>AI-powered Interview</span> with us</Heading>







                    <Text fontSize={isMobile ? 'lg' : 'xl'} textAlign='center' textColor={'gray.200'} width={{ base: '70vw', lg: '45rem' }}>
                        CareerNinja is a better way to prepare for interviews. Our virtual interviews are instant, dynamic, and flexible.
                    </Text>
                </Flex>



                {!isDesktop ? (
                    <>

                        <VStack width={isMobile ? '95vw' : '75vw'}>
                            <Box height='4rem' />

                            <Flex data-aos="fade-up" display="flex" flexDirection="column" alignItems='center' >


                                <Heading mb='2' mt='6' w='85%' size='lg' textColor='white'>
                                    Speech-to-text functionality
                                </Heading>

                                <Text fontSize='lg' w='85%' mb='10' textColor={'gray.300'}>
                                    {text1}
                                </Text>

                                <Image src={'/gallery/STTLight.png'} w="90%" h="100%" objectFit="cover" borderRadius='2xl' />
                            </Flex>
                            <Box h='5rem' />

                            <Flex data-aos="fade-up" display="flex" flexDirection="column" alignItems='center' >
                                <Heading mb='2' mt='6' w='85%' size='lg' textColor='white'>
                                    Dynamic questions
                                </Heading>

                                <Text fontSize='lg' w='85%' mb='10' textColor={'gray.300'}>
                                    {text2}
                                </Text>

                                <Image src={'/gallery/QuestionLight.png'} w="90%" h="100%" objectFit="cover" borderRadius='2xl' />

                            </Flex>

                            <Box h='5rem' />

                            <Flex data-aos="fade-up" display="flex" flexDirection="column" alignItems='center' >
                                <Heading mb='2' mt='6' w='85%' size='lg' textColor='white'>
                                    Personalized feedback
                                </Heading>

                                <Text fontSize='lg' w='85%' mb='10' textColor={'gray.300'}>
                                    {text3}
                                </Text>

                                <Image src={'/gallery/FeedbackLight.png'} w="90%" h="100%" objectFit="cover" borderRadius='2xl' />
                            </Flex>
                        </VStack>
                        <Box height='8rem' />

                        <Flex
                            shadow="lg"
                            height={{ base: "22rem", md: "19rem" }}
                            width="85vw"
                            borderRadius="lg"
                            bgGradient="linear(to-r, green.700, green.200)"
                            alignItems="start" // Vertically center the content
                            flexDirection="column"
                            data-aos='fade-up'

                            justifyContent="center" // Horizontally center the content
                        >
                            <Box width="80%" ms="6" >
                                <Heading size="xl" textColor={"white"} mb="4">
                                    Start acing your interviews
                                </Heading>
                                <Text fontSize="lg" textColor={"white"} mb='4'>
                                    CareerNinja can help pinpoint your weaknesses and make you a better job candidate.
                                </Text>
                                <Button colorScheme='green' onClick={() => navigate('/industry')}>Try it out</Button>
                            </Box>
                            <Flex width='100%' alignItems='end' justifyContent='end'>
                                <Image height="5rem" src="/ninja.png" objectFit="cover" me="2" />
                            </Flex>
                        </Flex>
                        <Box h='10vh' />

                        <Flex position='relative' bottom={4}>
                            <Text fontSize='sm'>
                                © 2023 CareerNinja. All Rights Reserved.
                            </Text>
                        </Flex>

                    </>


                ) :


                    (
                        <>
                            <VStack width={{ base: "50rem", md: '55rem', lg: '65rem' }}>
                                <Box height='9rem' />

                                <Flex height='25rem' data-aos='fade-up'>
                                    <Box height='80%' width='55%' shadow='lg' >
                                        <Image src={'/gallery/STTLight.png'} w="100%" h="100%" objectFit="cover" borderRadius='2xl' />
                                    </Box>
                                    <Spacer />
                                    <Box height='100%' width='40%' mt='5'>
                                        <Heading mb='4' mt='6' size='xl' textColor='white'>
                                            Speech-to-text functionality
                                        </Heading>

                                        <Text fontSize='xl' textColor={'gray.300'}>
                                            {text1}
                                        </Text>
                                    </Box>
                                </Flex>
                                <Box h='8rem' />

                                <Flex height='25rem' data-aos='fade-up'>

                                    <Box height='100%' width='35%' mt='5'>
                                        <Heading mb='4' mt='6' textColor='white'>
                                            Dynamic questions
                                        </Heading>

                                        <Text fontSize='xl' textColor={'gray.300'}>
                                            {text2}
                                        </Text>
                                    </Box>
                                    <Spacer />

                                    <Box height='70%' width='60%' shadow='lg' >
                                        <Image src={'/gallery/QuestionLight.png'} w="100%" h="100%" objectFit="cover" borderRadius='2xl' />
                                    </Box>
                                </Flex>

                                <Box h='5.5rem' />

                                <Flex height='25rem' data-aos='fade-up'>
                                    <Box height='85%' width='55%' shadow='lg' >
                                        <Image src={'/gallery/FeedbackLight.png'} w="100%" h="100%" objectFit="cover" borderRadius='2xl' />
                                    </Box>
                                    <Spacer />
                                    <Box height='100%' width='40%' mt='5'>
                                        <Heading mb='4' mt='6' textColor='white'>
                                            Personalized feedback
                                        </Heading>

                                        <Text fontSize='xl' textColor={'gray.300'}>
                                            {text3}
                                        </Text>
                                    </Box>
                                </Flex>

                            </VStack>
                            <Box h='10rem' />


                            <Flex
                                shadow="lg"
                                width={{ lg: '65rem', xl: '70vw' }}
                                borderRadius="lg"
                                bgGradient="linear(to-r, green.700, green.200)"
                                alignItems="center" // Vertically center the content
                                justifyContent="center" // Horizontally center the content
                                data-aos='fade-up'
                                py='10'
                            >
                                <Box width="80%" ms="10" >
                                    <Heading size="xl" textColor={"white"} mb="6">
                                        Start acing your interviews
                                    </Heading>
                                    <Text fontSize="lg" mb='6' textColor='gray.100' width='50%'>
                                        CareerNinja can help pinpoint your weaknesses and make you a better job candidate.
                                    </Text>
                                    <Button colorScheme='green' onClick={() => navigate('/industry')}>Try it out</Button>
                                </Box>
                                <Spacer />
                                <Box width="15%">
                                    <Image height="7rem" src="/ninja.png" objectFit="cover" me="2" />
                                </Box>
                            </Flex>
                            <Box h='10vh' />

                            <Flex position='relative' bottom={4}>
                                <Text fontSize='sm'>
                                    © 2023 CareerNinja. All Rights Reserved.
                                </Text>
                            </Flex>
                        </>

                    )


                }





            </Flex>










        </div >
    );
}

export default IntroPage;