import React, { useState, useRef } from "react";
import {
    Box,
    Center,
    Input,
    Flex,
    Heading,
    SimpleGrid,
    Textarea,
    Spacer,
    Button,
    Text,
    AbsoluteCenter,
    IconButton,
    useBreakpointValue,
    Image,
    useColorMode,
    HStack,
    Badge,
    Alert,
    AlertIcon,
    useDisclosure,
    Highlight,
    VStack,
    Wrap,
    WrapItem,
    Tooltip
} from "@chakra-ui/react";
import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import { BsRecordCircle, BsStopCircleFill, BsLightbulb } from 'react-icons/bs';


function Test(props) {
    const { colorMode, toggleColorMode } = useColorMode();
    const isMobile = useBreakpointValue({ base: true, lg: false });

    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.400)" : "linear(to-r, green.400, green.600)";

    const gradientColors2 = colorMode === 'dark'
        ? "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))"
        : "linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25))";




    return (
        <div>
            <Box h='20vh' />

            <Box width='100vw' height='10rem' backgroundColor='red'>
                <Center >
                    <Box backgroundColor='#FFFFFF'>
                        <Heading p='4' size='4xl' bgClip='text' bgGradient={gradientColors}>CareerNinja</Heading>
                    </Box>
                </Center>
            </Box>


            <Box backgroundColor='blue.400'>
                <Button leftIcon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />} size='md' variant='solid' onClick={toggleColorMode}>
                    {colorMode == 'dark' ? "Dark Mode" : "Light Mode"}
                </Button>) : (<IconButton onClick={toggleColorMode} size='md' icon={colorMode == 'dark' ? <MoonIcon /> : <SunIcon />} />)}
                <Center>
                    <Box backgroundColor={colorMode == 'dark' ? 'gray.800' : 'gray.50'} width='42rem' h='22rem' alignItems='center' justifyContent='center' display='flex'>
                        <VStack>


                            <Box transition="opacity .5s ease" width={{ base: '90vw', md: '30rem' }} height='8rem' boxShadow='md' rounded='lg' css={{
                                // backdropFilter: "blur(8px)",
                                backgroundImage: gradientColors2, // Adjust the transparency here
                            }}>


                                <Textarea placeholder='Listening...' height='100%' textColor='white' css={{
                                    '&::-webkit-scrollbar': {
                                        width: '0.4em',  // Adjust the width as per your preference
                                    },
                                    '&::-webkit-scrollbar-track': {
                                        background: 'transparent',
                                    },
                                    '&::-webkit-scrollbar-thumb': {
                                        background: 'transparent',
                                    },
                                    "::placeholder": {
                                        color: "white", // Change this to your desired placeholder text color
                                    },
                                }} />


                            </Box>
                            <Box textAlign="center" bgGradient={colorMode == 'dark' ? 'linear(to-r, gray.600, gray.700)' : 'linear(to-r, gray.50, gray.50)'} rounded='lg' boxShadow='dark-lg' display="flex" alignItems="center" justifyContent='center' px='4' maxWidth='90vw'>

                                <Box pb='2' pt='3' px='1' maxWidth='100%'>
                                    <Text transition="opacity .5s ease" fontSize='md' mb={3}>Interview ends at 5:23 PM (23 minutes)</Text>

                                    <Wrap justify="center" spacing={2} pb={2} maxWidth="100%">
                                        <WrapItem>
                                            <Button
                                                colorScheme="orange"
                                                size="sm"
                                                isDisabled
                                            >
                                                Skip Question
                                            </Button>
                                        </WrapItem>
                                        <WrapItem>
                                            <Button
                                                colorScheme="orange"
                                                rightIcon={<BsLightbulb />}
                                                size="sm"
                                                isDisabled
                                            >
                                                Hint
                                            </Button>
                                        </WrapItem>
                                        <WrapItem>
                                            <Button
                                                size="sm"
                                                loadingText="Loading"
                                                colorScheme={'red'}
                                                rightIcon={<BsRecordCircle size={18} />}
                                            >
                                                {'End Response'}
                                            </Button>
                                        </WrapItem>
                                        <WrapItem >
                                            <Tooltip >
                                                <Button
                                                    colorScheme="whatsapp"
                                                    size="sm"
                                                    isDisabled
                                                    rightIcon={<ArrowForwardIcon />}
                                                >
                                                    Submit
                                                </Button>
                                            </Tooltip>
                                        </WrapItem>
                                    </Wrap>
                                </Box>
                            </Box>
                        </VStack >
                    </Box>




                </Center>
                <Box height='10vh' />

                <Center>
                    <Box backgroundColor='gray.800' width='42rem' h='25rem' alignItems='center' justifyContent='center' display='flex'>

                        <Box width={!isMobile ? '38rem' : '90vw'} height="7rem" textAlign="start" bgGradient={colorMode == 'dark' ? "linear(to-r, gray.600, gray.700)" : "linear(to-r, gray.50, gray.50)"} rounded='lg' boxShadow='dark-lg' display="flex" alignItems="center" justifyContent='start' px='4' >
                            <Image height={{ base: '2.5rem', md: '3.5rem' }} src='/ninja.png' objectFit='cover' me='3' />
                            {/* https://www.flaticon.com/free-icon/ninja_1071164 */}
                            <Box overflow="auto" height="5rem" width='100%' justifyContent='center' alignItems='center' css={{
                                '&::-webkit-scrollbar': {
                                    width: '0.4em',  // Adjust the width as per your preference
                                },
                                '&::-webkit-scrollbar-track': {
                                    background: 'transparent',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    background: 'transparent',
                                },
                            }}>
                                <Heading as='h5' size='sm'>
                                    <span style={{ marginRight: '10px' }}>{"That sounds like a very interesting project! What challenges or setbacks did you face during the process, and how did you overcome them?"}</span>

                                </Heading>

                            </Box>


                        </Box >


                    </Box>
                </Center>
                <Box height='10vh' />

            </Box >

        </div >
    );
}

export default Test;