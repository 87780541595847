import React, { useState, useRef } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useColorMode,
    Button,
    useBreakpointValue,
    HStack,
    StackDivider,
    Box,
    Center,
    Text,
    VStack,
    Heading,
    ButtonGroup,
    Link,
    IconButton,
    UnorderedList,
    ListItem,
    Tab,
    Tabs,
    TabPanel,
    TabList,
    TabPanels,
    Divider

} from "@chakra-ui/react";
import { AiOutlineLinkedin } from "react-icons/ai";
import { BiLogoGmail } from "react-icons/bi"
import { BsLinkedin, BsGithub, BsInstagram } from "react-icons/bs";
import { MdHome } from 'react-icons/md';
import { ExternalLinkIcon } from '@chakra-ui/icons'

function AboutUsModal(props) {
    const { isOpen, onOpen, onClose } = props
    const { colorMode, toggleColorMode } = useColorMode();

    const borderColor = colorMode === 'dark' ? "gray.500" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, md: false });


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minW={{ base: '95vw', md: '40rem', lg: '42rem' }} maxW={{ base: '95vw', md: '40rem', lg: '42rem' }}>
                    <ModalHeader mt='2'>About CareerNinja</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody >
                        <VStack align="start" spacing={3} mb='3'>
                            <Text fontSize='md'>As college students, we understand how challenging it is to anticipate and prepare for interviews. That's why we decided to develop CareerNinja -- a site that will help anybody nail an interview. </Text >
                            <Text mb=''>
                                Unlike other platforms that connect you with individuals or use generic interview questions, CareerNinja uses AI to instantly generate dynamic and realistic questions, as well as provide personalized feedback once you've finished.

                            </Text >
                            <Text mb='2'>
                                Try using the job description of an interview you're preparing for, or try using any relevant job description just for practice.


                            </Text >
                            <Text fontWeight="bold">Here are some real examples:</Text>
                        </VStack>


                        <Tabs>
                            <TabList>
                                <Tab>Financial Analyst</Tab>
                                <Tab>Premed</Tab>
                                <Tab>{isMobile ? "ML Engineer" : "Machine Learning Engineer"}</Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                    <p>“Can you provide an example of a time when you had to ensure compliance with regulatory reporting guidelines?”</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>“Do you have any experience working with regenerative medicine, such as STEM cell research?”</p>
                                </TabPanel>
                                <TabPanel>
                                    <p>“Based on your experience in ML inference and parallel computing, can you explain your approach to maximizing performance in neural networks?” </p>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                        <Divider mb='6' />


                        <VStack align="start" spacing={2} >
                            <Text color={colorMode == 'light' ? "red.500" : 'red.300'}>
                                Note: We are still in beta stages, so results may not be perfect. Desktop is recommended.
                            </Text>

                            <Link href="https://www.flaticon.com/free-icons/ninja" isExternal color={colorMode == 'light' ? "orange.500" : 'orange.400'}>
                                <Text>
                                    Ninja icons created by Freepik - Flaticon <ExternalLinkIcon mx='2px' />
                                </Text>
                            </Link>


                            <Link href="https://forms.gle/P8oGbB22SKGVdC7j7" isExternal color={colorMode == 'light' ? "orange.500" : 'orange.400'}>
                                <Text>
                                    Feedback Form <ExternalLinkIcon mx='2px' />
                                </Text>
                            </Link>
                        </VStack>



                        {/* <HStack spacing='18px' width='100%' divider={<StackDivider borderColor='gray.200' />}>
                            <Box width='100%' bg='' alignText='center'>
                                <Center mb='5'>
                                    <Heading as='h4' size='md' alignText='center'>
                                        Kito Pang
                                    </Heading>


                                </Center>
                                <Center>
                                    <ButtonGroup spacing={isMobile ? 3 : 6}>
                                        <Box as="a" target="_blank" href="https://www.linkedin.com/in/kitopang">
                                            <IconButton icon={<BsLinkedin />} isExternal></IconButton>
                                        </Box>
                                        <Box as="a" target="_blank" href="https://github.com/kitopang">
                                            <IconButton icon={<BsGithub />}></IconButton>
                                        </Box>
                                        <Box as="a" target="_blank" href="mailto:kito.pang1@gmail.com">
                                            <IconButton icon={<BiLogoGmail />}></IconButton>
                                        </Box>
                                    </ButtonGroup>
                                </Center>
                            </Box>
                            <Box width='100%' bg=''>
                                <Center mb='5'>
                                    <Heading as='h4' size='md'>
                                        Rohan Sharma
                                    </Heading>
                                </Center>
                                <Center>
                                    <ButtonGroup spacing={isMobile ? 3 : 6}>
                                        <Box as="a" target="_blank" href="https://www.linkedin.com/in/rohan77">
                                            <IconButton icon={<BsLinkedin />} isExternal></IconButton>
                                        </Box>
                                        <Box as="a" target="_blank" href="https://www.rohansharma.fyi">
                                            <IconButton icon={<MdHome />} isExternal></IconButton>
                                        </Box>
                                        <Box as="a" target="_blank" href="mailto:contact@rohansharma.fyi">
                                            <IconButton icon={<BiLogoGmail />}></IconButton>
                                        </Box>

                                    </ButtonGroup>
                                </Center>
                            </Box>
                        </HStack> */}


                    </ModalBody>


                    <ModalFooter>
                        {/* <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button variant='ghost'>Secondary Action</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal >
        </>
    );
}

export default AboutUsModal;