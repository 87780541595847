import React, { useState, useRef, useEffect } from "react";
import { ChakraProvider, extendTheme, Container, useColorMode } from '@chakra-ui/react';
import IntroPage from "./IntroPage";
import customTheme from "./theme";
import Test from "./Test";
import FeedbackPage from "./Interview/FeedbackPage";

import ResumePage from "./Inputs/ResumePage";
import JobDescriptionPage from "./Inputs/JobDescriptionPage";
import IndustryPage from "./Inputs/IndustryPage";


import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router, Route, Routes, browserHistory, Navigate } from "react-router-dom";
import InterviewPageV2 from './Interview/InterviewPageV2';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';


import '@fontsource/raleway/400.css'
import '@fontsource/roboto/400.css'


// CALL HEROKU COMMAND TO ENABLE SSL CERT //    

function App() {
  // This is all stuff to be shared between pages
  const [uuid, setUUID] = useState(uuidv4())
  const [name, setName] = useState('')

  // Store feedback to be used between /interview and /feedback pages
  const [overallFeedback, setOverallFeedback] = useState('')
  const [questionFeedback, setQuestionFeedback] = useState([])

  const [TTSAudio, setTTSAudio] = useState(new Audio())

  // User input stuff
  const [yearsExperience, setYearsExperience] = useState("Select experience")
  const [industry, setIndustry] = useState("Select industry")
  const [practiceOption, setPracticeOption] = useState(0)
  const [resumeText, setResumeText] = useState('');
  const [jobDescriptionText, setJobDescriptionText] = useState('');




  // User disconnection hook 
  useEffect(() => {
    window.addEventListener("beforeunload", removeUser);
    return async () => {
      window.removeEventListener("beforeunload", removeUser);
    };
  }, []);


  const removeUser = async (e) => {
    await axios.post('http://localhost:5000/user_disconnect', {
      id: uuid,
    });
  };


  return (
    <ChakraProvider theme={customTheme}>
      <Router>
        <Routes>

          <Route
            path="/"
            element={
              <IntroPage
                uuid={uuid}
                setName={setName}
                resumeText={resumeText}
                setResumeText={setResumeText}
                jobDescriptionText={jobDescriptionText}
                setJobDescriptionText={setJobDescriptionText}
                TTSAudio={TTSAudio}
              />
            }
          />
          <Route path="/interview" element={<InterviewPageV2 uuid={uuid} name={name} setOverallFeedback={setOverallFeedback} setQuestionFeedback={setQuestionFeedback} TTSAudio={TTSAudio} setTTSAudio={setTTSAudio} />} />
          <Route path="/feedback" element={<FeedbackPage name={name} overallFeedback={overallFeedback} questionFeedback={questionFeedback} setOverallFeedback={setOverallFeedback} setQuestionFeedback={setQuestionFeedback} uuid={uuid} />} />
          <Route path="/test" element={<Test />} />
          <Route path="/industry" element={<IndustryPage setYearsExperience={setYearsExperience} yearsExperience={yearsExperience} industry={industry} setIndustry={setIndustry} practiceOption={practiceOption} setPracticeOption={setPracticeOption} uuid={uuid} />} />
          <Route path="/resume" element={<ResumePage jobDescriptionText={jobDescriptionText} setJobDescriptionText={setJobDescriptionText} resumeText={resumeText} setResumeText={setResumeText} uuid={uuid} TTSAudio={TTSAudio} practiceOption={practiceOption} industry={industry} yearsExperience={yearsExperience} />} />
          <Route path="/jobdescription" element={<JobDescriptionPage jobDescriptionText={jobDescriptionText} setJobDescriptionText={setJobDescriptionText} resumeText={resumeText} setResumeText={setResumeText} uuid={uuid} TTSAudio={TTSAudio} practiceOption={practiceOption} industry={industry} yearsExperience={yearsExperience} />} />
          {/* Handle unrecognized routes and redirect them to home page */}
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </Router>
    </ChakraProvider >
  );
}

export default App;
