import { extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'dark',
    useSystemColorMode: false,
};

const customBreakpoints = {
    // Customize the breakpoints to your desired values
    sm: '20em', // Small screens (width >= 20em)
    md: '45em', // Medium screens (width >= 40em)
    lg: '72em', // Large screens (width >= 64em)
    xl: '87em', // Extra-large screens (width >= 80em)
};



const theme = extendTheme({
    config,
    breakpoints: customBreakpoints,

});

export default theme;