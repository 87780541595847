import React, { useState, useRef, useEffect } from "react";
import {
    Textarea,
    Spacer,
    Button,
    useBreakpointValue,
    useColorMode,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Heading,
    Center,
    Select,

    Flex
} from "@chakra-ui/react";


import axios from 'axios';

import { ArrowForwardIcon, SunIcon, MoonIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { BsUpload } from 'react-icons/bs'
import { MdOutlineContentCopy, MdOutlineContactPage } from 'react-icons/md'

import DescriptionCard from "./DescriptionCard";

import SelectionCard from "../../Inputs/SelectionCard";
import { useNavigate } from 'react-router-dom';



function UseOursModal(props) {

    const { colorMode, toggleColorMode } = useColorMode();

    const { inputType, useOursIsOpen, closeUseOurs, openUseOurs, data, setResumeText, setJobDescriptionText, jobDescriptionText, resumeText, uuid } = props
    const navigate = useNavigate();





    // let called = false
    // useEffect(() => {
    //     // Function to be called on component initialization
    //     if (!called) {
    //         if (inputType == "resume") {
    //             setResumeText(data[currentIndex].resume)
    //         } else {
    //             setJobDescriptionText(data[currentIndex].jobDescription)
    //         }
    //     }
    // }, []);


    const borderColor = colorMode === 'dark' ? "gray.600" : "gray.400";
    const gradientColors = colorMode === 'dark' ? "linear(to-r, green.200, green.700)" : "linear(to-r, green.400, green.700)";
    const textColor = colorMode === 'dark' ? '#66CC99' : '#008000';
    const isMobile = useBreakpointValue({ base: true, lg: false });

    const handleSelectChange = (event) => {
        const newIndex = event.target.selectedIndex;

    };

    const handleJobSubmit = async () => {
        setJobDescriptionText(data[0].jobDescription)

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/initialize`, {
                id: uuid,
                resume: resumeText,
                job_description: "replace"
            });
            // data[currentIndex].jobDescription
            // Navigate to interview page first and handle analytics stuff after
            navigate('/interview');

            const uniqueID = localStorage.getItem('uniqueID');

            // Check if the user is unique (via MongoDB)
            try {

                const isUniqueUserResponse = await axios.post(`${process.env.REACT_APP_SERVER_URL}/checkUniqueUser`, {
                    uniqueID
                });

                if (isUniqueUserResponse.data.isUnique) {
                    // If the user is unique, trigger the Simple Analytics event
                    window.sa_event('unique_interview_visit');
                }
            } catch (error) {
                console.error('Error checking unique user:', error);
            }

            // console.log("STILL GOING!!!")


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    const handleResumeSubmit = () => {
        setResumeText("replace")
        navigate('/jobdescription')

    }


    return (
        <div>
            <Modal onClose={closeUseOurs} isOpen={useOursIsOpen}>
                <ModalOverlay zIndex='10' />
                <ModalContent minWidth={{ base: '95vw', md: '35rem' }} maxWidth={{ base: '95vw', md: '35rem' }}>
                    <ModalHeader></ModalHeader>
                    <ModalCloseButton />
                    <ModalBody px='12'>
                        <Flex direction="column" alignItems="center" mb="10">
                            <Heading size='md' width={isMobile ? '85vw' : '100%'} fontWeight='semibold' mb='12' mt='5' textAlign='center'>

                                Curious to see CareerNinja in action?   <br /> We have found a {inputType == "resume" ? "resume" : "job description"} related to your field.

                            </Heading>
                            <DescriptionCard currObject={data[0]} inputType={inputType} />

                        </Flex>
                    </ModalBody>
                    <ModalFooter>
                        <Button me='2' onClick={closeUseOurs} >Cancel</Button>
                        <Button onClick={inputType == "resume" ? (handleResumeSubmit) : (handleJobSubmit)} colorScheme="blue">Continue</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </div >
    );
}

export default UseOursModal;